.ImageEditable {
    margin-top: 20px;
    width: 300px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.ImageEditable>p {
    font-size: 14px;
    font-weight: bold;
}

.ImageEditable>img {
    width: 93%;
    margin-bottom: 4px;
}

.ImageEditable>input {
    display: none;
}

.ImageEditable>.button {
    width: 93%;
}

.ImageEditable>.upload_favicon_button,
.ImageEditable>.upload_favicon_button:hover,
.ImageEditable>.upload_favicon_button:focus,
.ImageEditable>.upload_favicon_button:active {
    border: 2px solid #006dff;
    background-color: #fff;
    color: #006dff;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto";
}

.ImageEditable>.upload_favicon_button-revert,
.ImageEditable>.upload_favicon_button-revert:hover,
.ImageEditable>.upload_favicon_button-revert:focus,
.ImageEditable>.upload_favicon_button-revert:active {
    border: 2px solid #24554e;
    background-color: #fff;
    color: #24554e;
    margin-top: 5px;
}
