.SelectItem {
   position: relative;
   display: inline-block;
   display: flex;
   width: 100%;
   flex-direction: column;
   gap: 8px;
}
.SelectItem .selectItem__label {
   display: flex;
   justify-content: space-between;
}
.SelectItem__options {
   overflow-y: auto;
   position: absolute;
   background-color: white;
   width: 100%;
   z-index: 9999;
   top: 100%;
   border-radius: 12px;
   z-index: 1000;
   border: 1px solid #e7e9e9;
   max-height: 300px;
   scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
   scrollbar-width: thin;
   box-sizing: border-box;
   text-align: center;
}
.SelectItem__options::-webkit-scrollbar-track {
   border-radius: 10px;
   margin: 5px 0px;
   background-color: transparent;
}
.SelectItem__options::-webkit-scrollbar {
   width: 3px;
   height: 2px;
   background-color: transparent;
}
.SelectItem__options::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   background-color: #e2e2e2;
}
.SelectItem__item {
   padding: 10px;
   cursor: pointer;
   max-width: 100%;
}
.SelectItem__button__large {
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   padding: 12px 16px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
}
.SelectItem__button__large__placeholder {
   color: #727978 !important;
}
.SelectItem__button__medium {
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   padding: 9px 16px;
   min-height: 44px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
}
.SelectItem__button__medium svg path {
   fill-background: #131f1e;
}
.SelectItem__button__medium__placeholder {
   color: #727978 !important;
}
.SelectItem__button {
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   padding: 8px 8px 8px 12px;
   display: flex;
   align-items: center;
   gap: 8px;
   cursor: pointer;
}
.SelectItem__button .SelectItem__button__icon {
   width: 17.67px;
   height: 17.67px;
}
.SelectItem__button .SelectItem__button__icon path {
   color: #727978;
}
.SelectItem__button__placeholder {
   color: #727978 !important;
}
.SelectItem__active svg:not(.disableIconAnimation) {
   transform: rotate(180deg);
}
.SelectItem__disabled {
   background: #f8fafa;
}
.SelectItem__button__transactions {
   padding: 12px 16px;
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   min-width: 223px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.SelectItem__button__transactions__placeholder {
   color: #727978 !important;
}
.divider-align-center{
   text-align: center;
}