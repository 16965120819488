body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
    scrollbar-width: thin;
    box-sizing: border-box;
    overflow: hidden;
}

body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

body::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: rgb(226, 226, 226);
}

.mark {
    outline-style: dotted;
    outline-color: #36796F;
    position: relative;
    /* z-index: 999; */
    /* background-color: #E8F2F1; */
    position: relative;
}

.mark:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.mark:before {
    /* content: "EDIT";
    font-size:16px;
    margin-top:-14px;
    border: 1px solid #D0D2D2;
    margin-left:-20px;
    position:absolute;
    top:50%;
    left:50%;
    background:white;
    cursor: pointer;
    padding:5px;
    border-radius: 12px;
    color: #D0D2D2;
    z-index: 99999999;
    line-height: 1; */
}

.section_delete_modal.ui.dimmer {
    border-radius: 24px !important;

}

.section_delete_modal.ui.modal>.content {
    padding: 0px 1.5rem 5px;
}

.section_delete_modal.ui.modal>.header {
    border-bottom: none;
}

.section_delete_modal.ui.modal>.actions {
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.ui.positive.button {
    background-color: #24554e !important;
}

.ui.negative.button {
    background-color: #d5624e !important;
}

.section_add {
    background: #FFFFFF;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 23px;
    padding-bottom: 1px;
    display: none;
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999999;
    cursor: pointer;
    color: #727978;
    align-items: center;
    border: 1px solid #D0D2D2;
    justify-content: center;
}

.section_add:hover {
    display: flex;
}

.Section:hover .section_add {
    display: flex;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=DM+Serif+Display:ital@0;1&family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Shrikhand&display=swap');


@font-face {
    font-family: 'Montserrat-Black';
    src: url("./fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-BlackItalic';
    src: url("./fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url("./fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url("./fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Italic';
    src: url("./fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url("./fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-LightItalic';
    src: url("./fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: url("./fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: url("./fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url("./fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-ThinItalic';
    src: url("./fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald-Bold';
    src: url("./fonts/Oswald/Oswald-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald-ExtraLight';
    src: url("./fonts/Oswald/Oswald-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald-Light';
    src: url("./fonts/Oswald/Oswald-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald-Medium';
    src: url("./fonts/Oswald/Oswald-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'Freehand-Regular';
    src: url("./fonts/Freehand/Freehand.ttf") format("truetype");
}

@font-face {
    font-family: 'Freehand-Bold';
    src: url("./fonts/Freehand/Freehand-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Bitter-Bold';
    src: url("./fonts/Bitter/Bitter-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Bitter-Italic';
    src: url("./fonts/Bitter/Bitter-Italic.ttf") format("truetype");
}

@font-face {
    font-family: 'Bitter-Regular';
    src: url("./fonts/Bitter/Bitter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald-Regular';
    src: url("./fonts/Oswald/Oswald-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: url("./fonts/Oswald/Oswald-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto-Light';
    src: url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto-Black';
    src: url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url("./fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-Black';
    src: url("./fonts/SourceSansPro/SourceSansPro-Black.ttf") format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url("./fonts/SourceSansPro/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url("./fonts/SourceSansPro/SourceSansPro-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url("./fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: ' OpenSans-Light';
    src: url("./fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url("./fonts/OpenSans/OpenSans-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: 'Alegreya';
    src: url("./fonts/alegreya/Alegreya-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'AlegreyaSans';
    src: url("./fonts/alegreyasans/AlegreyaSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'AnonymousPro';
    src: url("./fonts/anonymouspro/AnonymousPro-Regular.ttf") format("truetype");
}

/* @font-face {
    font-family: 'ArchivoNarrrow';
    src: url("./fonts/archivonarrrow/ArchivoNarrrow-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'Arvo';
    src: url("./fonts/arvo/Arvo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'BioRhyme';
    src: url("./fonts/biorhyme/BioRhyme-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Cabin';
    src: url("./fonts/cabin/Cabin-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Cardo';
    src: url("./fonts/cardo/Cardo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Chivo';
    src: url("./fonts/chivo/Chivo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Cormorant';
    src: url("./fonts/cormorant/cormorant/Cormorant-Regular.ttf") format("truetype");
}

/* @font-face {
    font-family: 'CormorantGaramont';
    src: url("./fonts/cormorant/cormorantgaramont/CormorantGaramont-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'CormorantInfant';
    src: url("./fonts/cormorant/cormorantinfant/CormorantInfant-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'CormorantSC';
    src: url("./fonts/cormorant/cormorantsc/CormorantSC-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'CormorantUnicase';
    src: url("./fonts/cormorant/cormorantunicase/CormorantUnicase-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'CormorantUpright';
    src: url("./fonts/cormorant/cormorantupright/CormorantUpright-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'CrimsonText';
    src: url("./fonts/crimsontext/CrimsonText-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Domine';
    src: url("./fonts/domine/Domine-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Eczar';
    src: url("./fonts/eczar/Eczar-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'FiraSans';
    src: url("./fonts/firasans/firasans/FiraSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'FiraSansCondensed';
    src: url("./fonts/firasans/firasanscondensed/FiraSansCondensed-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'FiraSansExtraCondensed';
    src: url("./fonts/firasans/firasansextracondensed/FiraSansExtraCondensed-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'GenBas';
    src: url("./fonts/gentiumbasic/GenBasR.ttf") format("truetype");
}

@font-face {
    font-family: 'Inconsolata';
    src: url("./fonts/inconsolata/Inconsolata-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Karla';
    src: url("./fonts/karla/Karla-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'LibreBaskerville';
    src: url("./fonts/librebaskerville/LibreBaskerville-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'LibreFranklin';
    src: url("./fonts/librefranklin/LibreFranklin-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Lora';
    src: url("./fonts/lora/Lora-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Merriweather';
    src: url("./fonts/merriweather/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Neuton';
    src: url("./fonts/neuton/Neuton-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'OldStandard';
    src: url("./fonts/oldstandardtt/OldStandard-Regular.ttf") format("truetype");
}

/* @font-face {
    font-family: 'PlayFairDisplay';
    src: url("./fonts/playfairdisplay/PlayFairDisplay-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'Poppins';
    src: url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'PT_Sans-Web';
    src: url("./fonts/ptsans/PT_Sans-Web-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'PT_Serif-Web';
    src: url("./fonts/ptserif/PT_Serif-Web-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Raleway';
    src: url("./fonts/raleway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'RobotoSlab';
    src: url("./fonts/robotoslab/RobotoSlab-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Rubik';
    src: url("./fonts/rubik/Rubik-Regular.ttf") format("truetype");
}

/* @font-face {
    font-family: 'SourceSerifPro';
    src: url("./fonts/sourcesanspro/SourceSerifPro-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'SpaceMono';
    src: url("./fonts/spacemono/SpaceMono-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Spectral';
    src: url("./fonts/spectral/Spectral-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'WorkSans';
    src: url("./fonts/worksans/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat';
    src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Oswald';
    src: url("./fonts/Oswald/Oswald-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Freehand';
    src: url("./fonts/Freehand/Freehand.ttf") format("truetype");
}

@font-face {
    font-family: 'Bitter';
    src: url("./fonts/Bitter/Bitter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Roboto';
    src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'SourceSansPro';
    src: url("./fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'OpenSans';
    src: url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'ProximaNova-Black';
    src: url("./fonts/proximaNova/Proxima-Nova-Black.otf") format("truetype");
}

@font-face {
    font-family: 'ProximaNova-Bold';
    src: url("./fonts/proximaNova/Proxima-Nova-Bold.otf") format("truetype");
}

@font-face {
    font-family: 'ProximaNova-Light';
    src: url("./fonts/proximaNova/Proxima-Nova-Light.otf") format("truetype");
}

@font-face {
    font-family: 'ProximaNova';
    src: url("./fonts/proximaNova/Proxima-Nova-Regular.otf") format("truetype");
}

@font-face {
    font-family: 'AvenirNext-Bold';
    src: url("./fonts/avenirnext/AvenirNext-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'AvenirNext-DemiBold';
    src: url("./fonts/avenirnext/AvenirNext-DemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'AvenirNext-Medium';
    src: url("./fonts/avenirnext/AvenirNext-Medium.ttf") format("truetype");
}

@font-face {
    font-family: 'AvenirNext-Regular';
    src: url("./fonts/avenirnext/AvenirNextLTPro-Regular.otf") format("truetype");
}

@font-face {
    font-family: 'Inter-Bold';
    src: url("./fonts/inter/Inter-Bold-slnt=0.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter-Medium';
    src: url("./fonts/inter/Inter-Medium-slnt=0.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter-Regular';
    src: url("./fonts/inter/Inter-Regular-slnt=0.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url("./fonts/inter/Inter-ExtraBold-slnt=0.ttf") format("truetype");
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url("./fonts/inter/Inter-SemiBold-slnt=0.ttf") format("truetype");
}

@font-face {
    font-family: 'Palatino-BoldItalic';
    src: local('./fonts/Palatino/Palatino Bold Italic'), local('Palatino-Bold-Italic'),
        url('./fonts/Palatino/Palatino-BoldItalic.woff2') format('woff2'),
        url('./fonts/Palatino/Palatino-BoldItalic.woff') format('woff'),
        url('./fonts/Palatino/Palatino-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Arial-Regular';
    src: local('Arial Regular'), local('Arial-Regular'),
        url('./fonts/arial/ArialMT.woff2') format('woff2'),
        url('./fonts/arial/ArialMT.woff') format('woff'),
        url('./fonts/arial/ArialMT.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Arial-Bold';
    src: local('Arial Bold'), local('Arial-Bold'),
        url('./fonts/arial-bold/Arial-BoldMT.woff2') format('woff2'),
        url('./fonts/arial-bold/Arial-BoldMT.woff') format('woff'),
        url('./fonts/arial-bold/Arial-BoldMT.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'PTSerif-Italic';
    src: local('PTSerif-Italic'), local('PTSerif-Italic'),
        url('./fonts/PT_Serif/PTSerif-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: local('Gilroy-Regular'), local('Gilroy-Regular'),
        url('./fonts/gilroy/regular/gilroy-regular.woff2') format('woff2'),
        url('./fonts/gilroy/regular/gilroy-regular.woff') format('woff'),
        url('./fonts/gilroy/regular/gilroy-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-SemiBold';
    src: local('Gilroy-SemiBold'), local('Gilroy-SemiBold'),
        url('./fonts/gilroy/semibold/gilroy-semibold.woff2') format('woff2'),
        url('./fonts/gilroy/semibold/gilroy-semibold.woff') format('woff'),
        url('./fonts/gilroy/semibold/gilroy-semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: local('Gilroy-Bold'), local('Gilroy-Bold'),
        url('./fonts/gilroy/bold/gilroy-bold.woff2') format('woff2'),
        url('./fonts/gilroy/bold/gilroy-bold.woff') format('woff'),
        url('./fonts/gilroy/bold/gilroy-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerif-Bold';
    src: local('NotoSerif-Bold'), local('NotoSerif-Bold'),
        url('./fonts/notoSerif/NotoSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerif-Bold';
    src: local('NotoSerif-Bold'), local('NotoSerif-Bold'),
        url('./fonts/notoSerif/NotoSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'NunitoSans-SemiBold';
    src: local('NunitoSans-SemiBold'), local('NunitoSans-SemiBold'),
        url('./fonts/nunitosans/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato-Bold'), local('Lato-Bold'),
        url('./fonts/lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: local('Lato-Regular'), local('Lato-Regular'),
        url('./fonts/lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), local('Lato-Light'),
        url('./fonts/lato/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'LucidaGrande';
    src: local('LucidaGrande'), local('LucidaGrande'),
        url('./fonts/lucidaGrande/LucidaGrandeBold.ttf') format('truetype');
}

@font-face {
    font-family: 'LucidaGrande';
    src: local('LucidaGrande'), local('LucidaGrande'),
        url('./fonts/lucidaGrande/LucidaGrandeBold.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Bold';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-ExtraBold';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-ExtraLight';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Light';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Medium';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Regular';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-SemiBold';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url('./fonts/BricolageGrotesque/BricolageGrotesque-SemiBold.ttf') format('truetype');
}


@import '~react-toastify/dist/ReactToastify.css';


.Toastify__toast {
    min-height: auto;
    padding: 15px;
    border-radius: 4px;
    font-family: "Avenir Next";
}

.Toastify__toast--success {
    background: #24554e;
}

.Toastify__toast--error {
    background: #cf0101;
}


.button-full {
    width: 100%;
}


a,
div.text,
p {
    word-break: break-word;
}

:root {
    --base: #22272F;
    --primary_90: #153833;
    --primary_80: #24554E;
    --primary_50: #36796F;
    --primary_40: #54938B;
    --primary_20: #7BAEA9;
    --primary_10: #A6C9C5;
    --primary_5: #E8F2F1;
    --greyscale_90: #131F1E;
    --greyscale_60: #444C4B;
    --greyscale_40: #727978;
    --greyscale_30: #A1A5A5;
    --greyscale_20: #D0D2D2;
    --greyscale_10: #E7E9E9;
    --greyscale_5: #F0F2F2;
    --greyscale_0: #FFFFFF;
    --blackscale_80: rgba(19, 31, 30, 0.8);
    --blackscale_70: rgba(19, 31, 30, 0.7);
    --blackscale_60: rgba(19, 31, 30, 0.6);
    --blackscale_40: rgba(19, 31, 30, 0.4);
    --blackscale_20: rgba(19, 31, 30, 0.2);
    --blackscale_10: rgba(19, 31, 30, 0.1);
    --blackscale_5: rgba(19, 31, 30, 0.05);
    --light: #EDF0EF;
    --dark_danger: #A61C23;
    --mid_dange: #F8C5C5;
    --danger: #D12D36;
    --light_danger: #FFF1F1;
    --success: #379552;
    --light_success: #DEFBE6;
    --alarm: #D3AC1E;
    --light_alarm: #FFF8E1;
    --link_active: #3060BD;
    --link_visited: #8830BD;
    --scale_90: #131F1E;
    --pink_1: #FEE1F2;
    --additional_error: #D12D36;
    --additional_success: #379552;
    --additional_danger: #D3AC1E;
}

.text_with_image_section_content {
    width: 100%;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

.ql-bubble,
.ql-editor {
    font-size: inherit !important;
    font-family: inherit !important;
    text-align: inherit !important;
    line-height: inherit !important;
}

.ql-editor {
    padding: 0 !important;
    overflow-y: visible !important;
}

.ql-editor:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}

.ql-bubble .ql-tooltip {
    z-index: 99999;
    line-height: 1;
}

.ql-toolbar {
    line-height: 1;
    height: 48px;
    display: flex;
    align-items: center;
}

.ql-bubble .ql-editor a {
    text-decoration: none;
}


.ql-bubble .ql-tooltip-editor input[type=text] {
    position: relative;
}

.ql-bubble .ql-picker {
    height: auto;
}

.ql-bubble .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: -7px;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
    max-height: 130px;
    scrollbar-width: thin;
    overflow-y: auto;

}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: rgb(226, 226, 226);
}

.ql-tooltip {
    width: 290px !important;
}


.quillButton .ql-tooltip {
    max-width: 290px !important;
    min-width: 103px !important;
    width: auto !important;
}

.ql-bubble .ql-picker.ql-font {
    width: 75px;
}

.ql-bubble .ql-picker-label::before {
    white-space: nowrap;
    overflow: hidden;
    width: 50px;
    text-overflow: ellipsis;
    position: relative;
    top: 2px;
    font-size: 14px;
}

.ql-bubble .ql-toolbar .ql-formats {
    margin-right: 2px;
}