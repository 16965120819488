.uploadModalWrapper {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.close__button__wrapper {
    position: absolute;
    top: 3px;
    right: 5px;
}

.uploaded__image__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.uploaded__image__wrapper .button__wrapper {
    position: absolute;
}

.uploaded__image__wrapper input {
    display: none;
}

.uploaded__image__wrapper img {
    width: 100%;
    border-radius: 12px;
}

.uploadModalWrapper.empty {
    width: 100%;
    margin-top: 12px;
    height: 226px;
    border: 1px solid #E7E9E9;
    border-radius: 12px;
}

.uploadModalWrapper.centered {
    justify-content: center;
}

.uploadModalWrapper:focus {
    border: none;
    outline: none;
}

.uploadModalWrapper .uploadArea {
    display: flex;
    justify-content: center;
    height: 100%;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
}

.uploadModalWrapper .uploadArea.dragging {
    border: 1px dashed rgba(63, 79, 101, 0.6);
}

.uploadModalWrapper .cropArea {
    max-height: calc(100% - 80px);
    width: 100%;
}

.uploadModalWrapper .dragInnerFooter {
    margin-top: 24px;
}

.uploadArea input {
    display: none !important;
}

.uploadArea {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: column;
}

.uploadModalClose {
    position: absolute;
    top: 40px;
    right: 40px;
}

.uploadModalClose :hover {
    cursor: pointer;
}

.uploadModalClose svg {
    width: 32px;
    height: 32px;
}

.originalImage {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.full-uploadprogress {
    width: 100%;
}

.full-uploadprogress .uploadCont_wrapper {
    width: 100%;
}

.cropAreaImage {
    display: flex;
    justify-content: center;
}

.cropAreaFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
}

.marginTop {
    margin-top: 16px;
}

.cropRatioText {
    display: flex;
    align-items: center;
}

.button_success_custom {
    background-color: #24554e !important;
    color: #fff !important;
}

.button_primary_custom {
    border: 2px solid #006dff !important;
    font-size: 16px !important;
    background-color: #fff !important;
    color: #006dff !important;
    font-family: 'AvenirNext-Bold';
    width: 100%;
}

.dragndropImage {
    text-align: center;
}

.dragndroptext {
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    user-select: none;
    font-family: 'AvenirNext-Bold';
    font-weight: 900;
    font-size: 20px;
    color: rgb(63, 79, 101);
}

.cropbutton {
    min-width: 100px !important;
}
