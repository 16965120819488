

.add-section-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 12px 16px;
    background: transparent;
    border-top: 0.5px solid #e4e4e4;
}

.add-section-left-side {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.add-section-items:hover {
    background-color: #e5e5e5;
    cursor: pointer;
}
.add-section-name-and-description {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; 
    padding: 0 15px; 
    color: #4f5863; 
}
.add-section-name {
    font-size:16px;
    font-weight: bold;
    padding-bottom:10px; 
}
.add-section-name-and-description {
    font-size: 12px;
}