

.add-section-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    background: transparent;
    border-top: none;
    padding: 0px;
}

.add-section-left-side {
    display: -webkit-box;
    display: -webkit-flex;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-section-left-side-icon{
    border-radius: 8px;
    display: flex;

}

.add-section-left-side:hover {
    cursor: pointer;
    background-color: inherit;
}
.add-section-name-and-description {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 15px;
    color: #4f5863;
}
.add-section-name {
    font-size:16px;
    font-weight: bold;
    padding-bottom:10px;
}
.add-section-name-and-description {
    font-size: 12px;
}

.SectionComponents__addSection{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.add-section-group{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.add-section-group-top{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.add-section-group-button{
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    transition: 0.2s all;
}

.add-section-group-item-new{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 100%;
    gap: 8px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    transition: outline 0.3s;
}

.hoverToAdd{
    height: 32px;
    width: 100%;
    background-color: #F8FAFA;
    border: 1px solid #E7E9E9;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-family: 'Roboto';
}

.hoverToAdd span{
    color: #727978;
    font-size: 12px;
    font-weight: bold;
}

.add-section-left-side-icon{
    width: 100%;
}

.add-section-left-side-icon svg{
    width: 100%;
}

.add-section-group-item-new:hover{
    outline: 1px solid #24554E;
}

.add-section-left-side-icon:hover .add-column-section {
    display: block; 
}

.add-section-left-side-icon .add-column-section{
    position: absolute;
    width: 91px;
    height: 36px;
    background-color: #24554E;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: white;
    font-weight: bold;
    line-height: 2px;
    display: none;
    cursor: pointer;
    margin: auto;
    inset: 0;
    bottom: 32px !important;
}