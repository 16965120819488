body p,
span {
    line-height: initial;
    word-wrap: break-word;
    word-break: break-word;
}

.sidebarContainer {
    background-color: #ffffff !important;
    max-width: 308px !important;
}

.ImageEditable {
    width: 100% !important;
}

.ImageEditable>div,
.ImageEditable>button,
.ImageEditable>input,
.ImageEditable>img {
    width: 100% !important;
}

#right_container {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.EditorDrag .sidebarContainer {
    overflow-x: hidden;
    border-right: 1px solid #E7E9E9;
    padding: 16px;
}

.EditorDrag .toggleSidebar {
    position: fixed;
    top: 3.5vh;
    background-color: white;
    z-index: 99999999;
    background: #FFFFFF;
    border: 1px solid #E7E9E9;
    border-radius: 0px 16px 16px 0px;
    cursor: pointer;
    width: 25px;
    height: 36px;
    display: flex;
    align-items: center;
}


.EditorDrag .toggleSidebar::after {
    position: fixed;
    content: '';
    top: 3.5vh;
    background-color: #fff;
    width: 2px;
    height: 36px;
    margin-left: -1px;
}


.add-section {
    width: 100%;
    color: #24554e !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 2px dashed #24554e;
    border-radius: 4px;
    padding: 11px 16px;
}

.add-section:hover {
    background-color: rgba(124, 183, 64, 0.1) !important;
    cursor: pointer;
}

.add-section:focus,
.add-new-section:focus,
.add-new-section_button:focus {
    outline: none !important;
}

.add-new-section_button {
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;
    color: #24554e;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-section_button img {
    margin-right: 10px;
}

.topbar {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #2d6dff;
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    transition: 0.5s all ease;
    overflow: hidden;
}

.right_container_wrapper {
    transition: flex 0.7s ease all;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right_container_style {
    background: #f4f7f7;
}

.phoneMode {
    transition: all 0.65s ease 0s !important;
    width: 375px;
    height: 667px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.phoneMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

#preview_button {
    width: 156px !important;
    padding: 7px 0 !important;
    border-radius: 40px !important;
    background-color: transparent !important;
    border: 2px solid white;
    text-align: center !important;
    color: #fff !important;
}

.centered_mainMenu_save_button {
    margin-left: 12px;
    border-radius: 4px;
    border: 2px solid #006dff;
    width: 130px;
    height: 36px;
}

#save_button {
    width: 100% !important;
    height: 100%;
    background-color: #f0f4f7;
    text-align: center !important;
    color: #006dff !important;
    font-size: 16px;
    font-weight: 600;
    font-family: 'AvenirNext-DemiBold';
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.43;
}

#save_button_exit {
    width: 100% !important;
    height: 100%;
    background-color: #f0f4f7;
    text-align: center !important;
    color: #006dff !important;
    font-size: 16px;
    font-weight: 600;
    font-family: 'AvenirNext-DemiBold';
    cursor: pointer;
    padding: 0 !important;
    line-height: 1.43;
}

.tabletMode {
    transition: all 0.65s ease 0s !important;
    width: 600px;
    height: 720px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.tabletMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.centered_mainMenu {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: flex-end;
}

.topbar_items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.topbar_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 4px !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 3.6px;
    background-color: #fff;
}

.topbar_item img {
    margin: 0 5px;
    height: 16px;
}

.topbar_item span {
    color: #ffffff;
    font-size: 16px;
}

.mainMenu {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* background-color: #2d6dff; */
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    transition: 0.5s all ease;
    /* height: 70px; */
    overflow: hidden;
    /* display: none; */
}

.bottomBar {
    padding: 16px 0;
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    right: 32px;
    z-index: 100;
}

.bottombar-items {
    width: -webkit-calc(100% - 350px);
    width: calc(100% - 350px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottombar-items-centered {
    width: 80%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.bottombar-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottombar-item-link:hover .bottombar-item-page {
    color: #ffffff;
    border: 2px solid #ffffff
}

.bottombar-item-link:hover .bottombar-item-text {
    color: #ffffff;
}

.bottombar-item-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.bottombar-item-page {
    height: 40px;
    width: 40px;
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Roboto-Medium', sans-serif;
}

.bottombar-item-text {
    font-size: 14px;
    color: #a2a2a2;
    font-family: 'Roboto-Medium', sans-serif;
}


.save_container {
    width: 350px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.settings_icon {
    margin-left: 140px !important;
}

.page-preview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.preview-icon {
    margin: 0 10px;
}

.ui.dividing.header {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 1% !important;
}

.SectionComponent {
    background-color: #FFFFFF !important;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #008055 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #16a977 !important;
}

.EditorDrag .SectionComponents .component_name {
    color: #59636f !important;
    font-family: 'AvenirNext-DemiBold';
    font-size: 13px;
    margin-left: 0 !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2d91fb !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.form .field>.selection.dropdown {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

body .ui.dividing.header {
    margin: 5px !important;
    border-bottom: none;
}

.EditorDrag .menu-item-right .ui.dropdown>.text {
    display: none;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditorDrag .sidebar_top_section_text,
.settings_icon {
    border: none !important;
    background: #f0f4f7;
    color: #fff;
}

.EditorDrag .settings_icon {
    position: absolute !important;
    right: 16px !important;
}

.EditorDrag .Tabs {
    background-color: #fff;
}

.EditorDrag .Tabs .ui.attached.tabular.menu {
    background-color: #F0F2F2 !important;
    border-radius: 12px;
    margin-bottom: 32px;
    border-bottom: none !important;
}

.EditorDrag .Tabs .ui.attached.tabular.menu .item {
    color: #131F1E !important;
    width: 33.3%;
    padding: 0;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 168%;
}

.EditorDrag .Tabs .ui.attached.tabular.menu .active.item {
    background-color: #24554E !important;
    color: #fff !important;
    border-radius: 12px !important;
}

.EditorDrag .Tabs .ui.attached.segment {
    border: none !important;
    background-color: #fff !important;
}


.EditorDrag .ui.vertical.SectionComponents.menu {
    box-shadow: none;
    border: none;
}

.elements__helper {
    position: relative;
    margin-top: 12px;
    display: flex;
    justify-content: center;
}

.elements__helper__icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.ui.uncover.sidebar {
    z-index: auto;
    transform: none !important;
    will-change: inherit;
}

.elements_content {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.elements_content .elementName {
    font-size: 14px;
    font-family: 'AvenirNext-Medium';
    padding: 4px 0;
    font-weight: 500;
    color: #3f4f65;
}

.element_content {
    background: #FFFFFF;
    border: 1px solid #E7E9E9;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
    height: 76px;
    width: 122px;
    transition: 0.3s all;
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.element_content_small {
    background: #fbfdff;
    padding: 0px;
    text-align: center;
    height: 70px;
    width: 70px;
    margin: 4px 0;
    border: 1px solid #cddaf1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .element_content:hover .elementIcon svg path:last-child {
    fill: #24554E;
} */

.element_content:hover {
    border: 1px solid #24554E;
    box-shadow: 0 0 0px 1px #24554E;
}

/* .EditorDrag .ui.form .field .ui.input input,
.EditorDrag .ui.form .field>.selection.dropdown,
.EditorDrag .ui.form .fields .field .ui.input input,
.EditorDrag .ui.form input:not([type]), .ui.form input[type=date],
.EditorDrag .ui.form input[type=datetime-local],
.EditorDrag .ui.form input[type=email],
.EditorDrag .ui.form input[type=file],
.EditorDrag .ui.form input[type=number],
.EditorDrag .ui.form input[type=password],
.EditorDrag .ui.form input[type=search],
.EditorDrag .ui.form input[type=tel],
.EditorDrag .ui.form input[type=text],
.EditorDrag.ui.form input[type=time],
.EditorDrag .ui.form input[type=url],
.EditorDrag .ui.selection.dropdown {
    font-family: 'AvenirNext-DemiBold';
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.4px;
    background: #fbfdff;
    border-radius: 4px !important;
    border: 1px solid #cddaf1;
    padding: 5px 10px;
    font-size: 13px;
    color: #3f4f65;
} */


.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer,
.EditorDrag #right_container {
    scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
    scrollbar-width: thin;
}

.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer::-webkit-scrollbar-track,
.EditorDrag #right_container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer::-webkit-scrollbar,
.EditorDrag #right_container::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer::-webkit-scrollbar-thumb,
.EditorDrag #right_container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: rgb(226, 226, 226);
}


.menu-item-right {
    display: flex;
}

.addSectionPopup {
    display: flex;
    width: 268px;
    padding: var(--spacing-xs, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs, 8px);
    border-radius: 12px;
    background: var(--grey-scale-0, #FFF);
    box-shadow: 1px 2px 2px 0px rgba(19, 31, 30, 0.04), 0px 4px 10px 0px rgba(19, 31, 30, 0.10);
}

.columnCanvas,
.smartBlocks {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #F8FAFA;
    border-radius: 10px;
}

.columnCanvas:hover,
.smartBlocks:hover {
    cursor: pointer;
    background-color: #E8F2F1;
}

.popupSection {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.smartBlocks strong,
.columnCanvas strong {
    font-size: 14px;
}

.smartBlocks span,
.columnCanvas span {
    font-size: 12px;
    color: #3f4f65;
}
