body p,
span {
    line-height: inherit;
}

.sidebarContainer * {
    font-family: "Roboto", serif;
}

.ImageEditable {
    width: 100% !important;
}

.ImageEditable>div,
.ImageEditable>button,
.ImageEditable>input,
.ImageEditable>img {
    width: 100% !important;
}

#right_container {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.Editor .sidebarContainer {
    background-color: #f7f8f9;
    overflow-x: hidden;
}

.Editor .toggleSidebar {
    position: absolute;
    top: 50vh;
    z-index: 999999;
    padding: 10px 2px;
    background: #fff;

}

.add-section {
    width: 100%;
    color: #2e91fc;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.add-section:hover {
    background: #b0c5df;
    color: #265d97;

}

.add-new-section {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.topbar {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #233a57;
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    transition: 0.5s all ease;
    overflow: hidden;
}

.right_container_wrapper {
    transition: flex 0.7s ease all;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.phoneMode {
    transition: all 0.65s ease 0s !important;
    width: 382px;
    height: 667px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.phoneMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.tabletMode {
    transition: all 0.65s ease 0s !important;
    width: 600px;
    height: 720px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.tabletMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.centered_topbar {
    width: 90%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.topbar_items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.topbar_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.topbar_item img {
    margin: 0 5px;
}

.topbar_item span {
    color: #ffffff;
    font-size: 16px;
}

.bottombar {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #233a57;
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    transition: 0.5s all ease;
    height: 70px;
    overflow: hidden;
}

.bottombar-items {
    width: -webkit-calc(100% - 350px);
    width: calc(100% - 350px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottombar-items-centered {
    width: 80%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.bottombar-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottombar-item-link:hover .bottombar-item-page {
    color: #ffffff;
    border: 2px solid #ffffff
}

.bottombar-item-link:hover .bottombar-item-text {
    color: #ffffff;
}

.bottombar-item-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.bottombar-item-page {
    height: 40px;
    width: 40px;
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Roboto-Medium', sans-serif;
}

.bottombar-item-text {
    font-size: 14px;
    color: #a2a2a2;
    font-family: 'Roboto-Medium', sans-serif;
}


.save_container {
    width: 350px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.settings_icon {
    margin-left: 140px !important;
}

.page-preview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.preview-icon {
    margin: 0 10px;
}

.ui.dividing.header {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 1% !important;
}

.SectionComponent label {
    color: #3f4f65 !important;
    font-weight: 600;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #008055 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #16a977 !important;
}

.SectionComponents .component_name {
    color: #59636f !important;
    font-weight: 800;
    font-size: 16px;
    margin-left: 15px;
}

.ui.tabular.menu .item {
    color: #3d2828de !important;
}

.ui.tabular.menu .active.item {
    color: #3d2828de !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2d91fb !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.form .field>.selection.dropdown {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

body .ui.dividing.header {
    margin-bottom: 15px !important;
}

.menu-item-right .ui.dropdown>.text {
    display: none;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}