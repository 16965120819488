  .uploadcare_widget {
      display: flex;
      justify-content: center;
      align-items: center;
  }


  .uploadcare--widget__button_type_open:focus {
      outline: none;
  }

  .uploadcare--widget__button_type_open {
      display: none;
  }

  .recommended__text {
      color: rgb(114, 121, 120);
      margin-top: 8px;
      font-size: 12px;
      font-weight: bold;
      line-height: 148%;
  }

  .uploadcare--widget__button_type_cancel {
      display: none;
  }

  #uploadcare--widget__text {
      display: none;
  }

  .uploadcare--progress_type_canvas {
      color: #24554e;
      margin: 10px 0 20px 0;
      display: none;
  }

  .upcare-upload-btn {
      display: flex;
      flex-direction: column;
  }

  .upcare-upload-btn button {
      width: 100%;
  }

  .uploadProgress__content {
      margin-bottom: 10px;
      margin-top: 10px
  }

  .uploadProgress__content .cancel_btn {
      display: flex;
      align-items: center;
  }

  .uploadProgress__content svg {
      position: relative;
      left: 6px;
      cursor: pointer;
  }


  .uploadProgress__content .uploadProgress {
      width: 100%;
      background-color: #ddd;
      border-radius: 15px;
      margin-top: 5px;
  }


  .uploadProgress__content .uploadBar {
      width: 0%;
      height: 12px;
      background-color: #24554e;
      border-radius: 15px;
  }

  .uploadProgress__content .upload_text {
      color: #8a94a2;
      font-size: 16px;
      line-height: 1.5;
      font-family: 'AvenirNext-Medium';
  }
