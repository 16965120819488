body p,
span {
    line-height: initial;
}

.sidebarContainer * {
    font-family: "AvenirNext", "Roboto", serif;
}

.sidebarContainer {
    background-color: #f0f4f7 !important;
}

.ImageEditable {
    width: 100% !important;
}

.ImageEditable>div,
.ImageEditable>button,
.ImageEditable>input,
.ImageEditable>img {
    width: 100% !important;
}

#right_container {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.Editor .sidebarContainer {
    overflow-x: hidden;
    padding: 16px;
}

.Editor .toggleSidebar {
    position: absolute;
    top: 50vh;
    left: 8px;
    z-index: 999999;
    padding: 10px 2px;
    background: #fff;

}

.add-section {
    width: 100%;
    color: #24554e !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 2px dashed #24554e;
    border-radius: 4px;
    padding: 11px 16px;
}

.add-section:hover {
    background-color: rgba(124, 183, 64, 0.1) !important;
    cursor: pointer;
}

.add-section:focus,
.add-new-section:focus,
.add-new-section_button:focus {
    outline: none !important;
}

.add-new-section_button {
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;
    color: #24554e;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-section_button img {
    margin-right: 10px;
}

.topbar {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #2d6dff;
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    transition: 0.5s all ease;
    overflow: hidden;
}

.right_container_wrapper {
    transition: flex 0.7s ease all;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right_container_style {
    background-color: #f0f4f7;
}

.phoneMode {
    transition: all 0.65s ease 0s !important;
    width: 375px;
    height: 667px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.phoneMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

#preview_button {
    width: 156px !important;
    padding: 7px 0 !important;
    border-radius: 40px !important;
    background-color: transparent !important;
    border: 2px solid white;
    text-align: center !important;
    color: #fff !important;
}

.centered_mainMenu_save_button {
    margin-left: 12px;
    border-radius: 4px;
    border: 2px solid #006dff;
    width: 130px;
    height: 36px;
}

#save_button {
    width: 100% !important;
    height: 100%;
    background-color: #f0f4f7;
    text-align: center !important;
    color: #006dff !important;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto";
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.43;
}

.tabletMode {
    transition: all 0.65s ease 0s !important;
    width: 600px;
    height: 720px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.tabletMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.centered_mainMenu {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: flex-end;
}

.topbar_items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.topbar_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 4px !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 3.6px;
    background-color: #fff;
}

.topbar_item img {
    margin: 0 5px;
    height: 16px;
}

.topbar_item span {
    color: #ffffff;
    font-size: 16px;
}

.mainMenu {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* background-color: #2d6dff; */
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    transition: 0.5s all ease;
    /* height: 70px; */
    overflow: hidden;
    /* display: none; */
}

.bottomBar {
    padding: 16px 0;
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    right: 32px;
    z-index: 100;
}

.bottombar-items {
    width: -webkit-calc(100% - 350px);
    width: calc(100% - 350px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottombar-items-centered {
    width: 80%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.bottombar-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottombar-item-link:hover .bottombar-item-page {
    color: #ffffff;
    border: 2px solid #ffffff
}

.bottombar-item-link:hover .bottombar-item-text {
    color: #ffffff;
}

.bottombar-item-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.bottombar-item-page {
    height: 40px;
    width: 40px;
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Roboto-Medium', sans-serif;
}

.bottombar-item-text {
    font-size: 14px;
    color: #a2a2a2;
    font-family: 'Roboto-Medium', sans-serif;
}


.save_container {
    width: 350px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.settings_icon {
    margin-left: 140px !important;
}

.page-preview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.preview-icon {
    margin: 0 10px;
}

.ui.dividing.header {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 1% !important;
}

.SectionComponent label {
    color: #3f4f65 !important;
    font-weight: 600;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #008055 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #16a977 !important;
}

.SectionComponents .component_name {
    color: #59636f !important;
    font-weight: 800;
    font-size: 16px;
    margin-left: 0 !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2d91fb !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.form .field>.selection.dropdown {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

body .ui.dividing.header {
    margin-bottom: 15px !important;
}

.menu-item-right .ui.dropdown>.text {
    display: none;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar_top_section_text,
.settings_icon {
    border: none !important;
    background: #f0f4f7;
    color: #fff;
}

.settings_icon {
    position: absolute !important;
    right: 16px !important;
}

.Tabs {
    background-color: #f0f4f7;
}

.Tabs .ui.attached.tabular.menu {
    background-color: #fff !important;
    border-radius: 4px;
    margin-bottom: 8px;
}

/* .Tabs .ui.attached.tabular.menu {
    background-color: #fbfdff;
} */

.Tabs .ui.attached.tabular.menu .item {
    color: #3f4f65 !important;
    width: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
}

.Tabs .ui.tabular.menu .active.item {
    font-weight: 500;
}

.Tabs .ui.attached.tabular.menu .active.item {
    background-color: #24554e !important;
    color: #fff !important;
    border-radius: 0 !important;
}

.Tabs .ui.attached.segment {
    border: none !important;
    background-color: #f0f4f7 !important;
}
