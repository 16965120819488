.modal__new {
   position: absolute;
}
.modal__new__background {
   background: rgba(19, 31, 30, 0.4);
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 9999;
   height: 100vh;
}
.modal__new__content {
   position: fixed;
   top: 50%;
   left: 50%;
   background: #fff;
   border-radius: 12px;
   padding: 20px;
   z-index: 9999;
   transform: translate(-50%, -50%);
   min-width: 400px;
}
