.triggle__popover {
   padding: 4px 0px;
   background: #fff;
   border: 1px solid rgba(19, 31, 30, 0.1);
   box-shadow: 0px 4px 10px rgba(19, 31, 30, 0.1), 1px 2px 2px rgba(19, 31, 30, 0.04);
   border-radius: 12px;
}
.triggle__popover__flex {
   display: flex;
   flex-direction: column;
}
.triggle__popover__item {
   cursor: pointer;
   display: flex;
   align-items: center;
   width: 100%;
   padding: 12px 16px;
   gap: 12px;
   background: #fff;
   border-top-right-radius: 12px;
}
.triggle__popover__item__border {
   border-top: 1px solid #e7e9e9;
   border-top-right-radius: 0;
}
.triggle__popover__item__trash path {
   fill: #d12d36;
}
.triggle__popover__item__trash span {
   color: #d12d36;
}
.triggle__popover__item__toggleOffM path {
   fill: #d12d36;
}
.triggle__popover__item__toggleOffM span {
   color: #d12d36;
}
.drop__triggle__icon {
   cursor: pointer;
   display: flex;
   align-items: center;
}
.triggle__popover__one {
   padding: 4px 0px;
   background: #fff;
   border: 1px solid rgba(19, 31, 30, 0.1);
   box-shadow: 0px 4px 10px rgba(19, 31, 30, 0.1), 1px 2px 2px rgba(19, 31, 30, 0.04);
   display: flex;
   border-radius: 12px;
   align-items: center;
   justify-self: center;
}
.triggle__popover__one .triggle__popover__flex {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-self: center;
}
.triggle__popover__one .triggle__popover__item {
   cursor: pointer;
   display: flex;
   align-items: center;
   padding: 7px 16px;
   gap: 12px;
   background: #fff;
}
.triggle__popover__one .triggle__popover__item__toggleOffM path {
   fill: #d12d36;
}
.triggle__popover__one .triggle__popover__item__toggleOffM span {
   color: #d12d36;
}
.MuiPaper-rounded {
   border-radius: 12px !important;
}
