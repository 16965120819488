@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Anton&family=DM+Serif+Display:ital@0;1&family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Shrikhand&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
    scrollbar-width: thin;
    box-sizing: border-box;
    overflow: hidden;
}

body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

body::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: rgb(226, 226, 226);
}

.mark {
    outline-style: dotted;
    outline-color: #36796F;
    position: relative;
    /* z-index: 999; */
    /* background-color: #E8F2F1; */
    position: relative;
}

.mark:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.mark:before {
    /* content: "EDIT";
    font-size:16px;
    margin-top:-14px;
    border: 1px solid #D0D2D2;
    margin-left:-20px;
    position:absolute;
    top:50%;
    left:50%;
    background:white;
    cursor: pointer;
    padding:5px;
    border-radius: 12px;
    color: #D0D2D2;
    z-index: 99999999;
    line-height: 1; */
}

.section_delete_modal.ui.dimmer {
    border-radius: 24px !important;

}

.section_delete_modal.ui.modal>.content {
    padding: 0px 1.5rem 5px;
}

.section_delete_modal.ui.modal>.header {
    border-bottom: none;
}

.section_delete_modal.ui.modal>.actions {
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 12px;
    gap: 12px;
}

.ui.positive.button {
    background-color: #24554e !important;
}

.ui.negative.button {
    background-color: #d5624e !important;
}

.section_add {
    background: #FFFFFF;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 23px;
    padding-bottom: 1px;
    display: none;
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999999;
    cursor: pointer;
    color: #727978;
    align-items: center;
    border: 1px solid #D0D2D2;
    justify-content: center;
}

.section_add:hover {
    display: flex;
}

.Section:hover .section_add {
    display: flex;
}


@font-face {
    font-family: 'Montserrat-Black';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Black.b4b207ec.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-BlackItalic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-BlackItalic.e656be39.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Bold.68c8d686.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-BoldItalic.be04bda2.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-ExtraBold.e3d5ae4a.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-ExtraLight.9c492f93.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-Italic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Italic.9b03884c.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Light.73b7fd8d.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-LightItalic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-LightItalic.00ec7fa4.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Medium.59814198.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-MediumItalic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-MediumItalic.0824018d.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Regular.7c8ccfe4.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-SemiBold.27dfd575.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-SemiBoldItalic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-SemiBoldItalic.fa336b43.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Thin.b76e925d.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat-ThinItalic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-ThinItalic.293891a8.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-Bold.c71e53ff.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald-ExtraLight';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-ExtraLight.38b409ef.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald-Light';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-Light.f7e43bbc.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald-Medium';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-Medium.99834887.ttf) format("truetype");
}

@font-face {
    font-family: 'Freehand-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Freehand.926cfec9.ttf) format("truetype");
}

@font-face {
    font-family: 'Freehand-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Freehand-Bold.a456bbad.ttf) format("truetype");
}

@font-face {
    font-family: 'Bitter-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Bitter-Bold.46bc29e4.ttf) format("truetype");
}

@font-face {
    font-family: 'Bitter-Italic';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Bitter-Italic.2eb18f5a.ttf) format("truetype");
}

@font-face {
    font-family: 'Bitter-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Bitter-Regular.8db0301b.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-Regular.b2443fe8.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-SemiBold.c1e746ea.ttf) format("truetype");
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Roboto-Regular.71779d80.ttf) format("truetype");
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Roboto-Bold.9630c546.ttf) format("truetype");
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Roboto-Light.057aa247.ttf) format("truetype");
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Roboto-Medium.ea7d3afe.ttf) format("truetype");
}

@font-face {
    font-family: 'Roboto-Black';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Roboto-Black.7f3ed21e.ttf) format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/SourceSansPro-Regular.73ae3a58.ttf) format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-Black';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/SourceSansPro-Black.1d3095ae.ttf) format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/SourceSansPro-Bold.7edc663e.ttf) format("truetype");
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/SourceSansPro-Semibold.1f6134fb.ttf) format("truetype");
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/OpenSans-Bold.043771c9.ttf) format("truetype");
}

@font-face {
    font-family: ' OpenSans-Light';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/OpenSans-Light.3d5923f9.ttf) format("truetype");
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/OpenSans-Regular.17e98b9e.ttf) format("truetype");
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/OpenSans-Semibold.029b9bc9.ttf) format("truetype");
}

@font-face {
    font-family: 'Alegreya';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Alegreya-Regular.7ba7ef8e.ttf) format("truetype");
}

@font-face {
    font-family: 'AlegreyaSans';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/AlegreyaSans-Regular.8358c398.ttf) format("truetype");
}

@font-face {
    font-family: 'AnonymousPro';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/AnonymousPro-Regular.a386580b.ttf) format("truetype");
}

/* @font-face {
    font-family: 'ArchivoNarrrow';
    src: url("./fonts/archivonarrrow/ArchivoNarrrow-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'Arvo';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Arvo-Regular.04ca96c3.ttf) format("truetype");
}

@font-face {
    font-family: 'BioRhyme';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/BioRhyme-Regular.8003f84c.ttf) format("truetype");
}

@font-face {
    font-family: 'Cabin';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Cabin-Regular.98738681.ttf) format("truetype");
}

@font-face {
    font-family: 'Cardo';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Cardo-Regular.2dc0460f.ttf) format("truetype");
}

@font-face {
    font-family: 'Chivo';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Chivo-Regular.90d381a1.ttf) format("truetype");
}

@font-face {
    font-family: 'Cormorant';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Cormorant-Regular.4ca6805a.ttf) format("truetype");
}

/* @font-face {
    font-family: 'CormorantGaramont';
    src: url("./fonts/cormorant/cormorantgaramont/CormorantGaramont-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'CormorantInfant';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/CormorantInfant-Regular.5fbb1d23.ttf) format("truetype");
}

@font-face {
    font-family: 'CormorantSC';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/CormorantSC-Regular.6b074ea3.ttf) format("truetype");
}

@font-face {
    font-family: 'CormorantUnicase';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/CormorantUnicase-Regular.526249d0.ttf) format("truetype");
}

@font-face {
    font-family: 'CormorantUpright';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/CormorantUpright-Regular.016821a4.ttf) format("truetype");
}

@font-face {
    font-family: 'CrimsonText';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/CrimsonText-Regular.1d10a175.ttf) format("truetype");
}

@font-face {
    font-family: 'Domine';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Domine-Regular.764089c8.ttf) format("truetype");
}

@font-face {
    font-family: 'Eczar';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Eczar-Regular.224a7282.ttf) format("truetype");
}

@font-face {
    font-family: 'FiraSans';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/FiraSans-Regular.4c2ffb7b.ttf) format("truetype");
}

@font-face {
    font-family: 'FiraSansCondensed';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/FiraSansCondensed-Regular.35aee2e5.ttf) format("truetype");
}

@font-face {
    font-family: 'FiraSansExtraCondensed';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/FiraSansExtraCondensed-Regular.62f0fe01.ttf) format("truetype");
}

@font-face {
    font-family: 'GenBas';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/GenBasR.f90d1b27.ttf) format("truetype");
}

@font-face {
    font-family: 'Inconsolata';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Inconsolata-Regular.e15132d1.ttf) format("truetype");
}

@font-face {
    font-family: 'Karla';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Karla-Regular.be21073c.ttf) format("truetype");
}

@font-face {
    font-family: 'LibreBaskerville';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/LibreBaskerville-Regular.e64484a1.ttf) format("truetype");
}

@font-face {
    font-family: 'LibreFranklin';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/LibreFranklin-Regular.a2e03f01.ttf) format("truetype");
}

@font-face {
    font-family: 'Lora';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Lora-Regular.ef15c7ea.ttf) format("truetype");
}

@font-face {
    font-family: 'Merriweather';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Merriweather-Regular.f137a865.ttf) format("truetype");
}

@font-face {
    font-family: 'Neuton';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Neuton-Regular.06ec9b33.ttf) format("truetype");
}

@font-face {
    font-family: 'OldStandard';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/OldStandard-Regular.85262a77.ttf) format("truetype");
}

/* @font-face {
    font-family: 'PlayFairDisplay';
    src: url("./fonts/playfairdisplay/PlayFairDisplay-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'Poppins';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Poppins-Regular.3c813f59.ttf) format("truetype");
}

@font-face {
    font-family: 'PT_Sans-Web';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/PT_Sans-Web-Regular.983a32f6.ttf) format("truetype");
}

@font-face {
    font-family: 'PT_Serif-Web';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/PT_Serif-Web-Regular.bb0d2bb9.ttf) format("truetype");
}

@font-face {
    font-family: 'Raleway';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Raleway-Regular.c268e80b.ttf) format("truetype");
}

@font-face {
    font-family: 'RobotoSlab';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/RobotoSlab-Regular.14803206.ttf) format("truetype");
}

@font-face {
    font-family: 'Rubik';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Rubik-Regular.b3a32aec.ttf) format("truetype");
}

/* @font-face {
    font-family: 'SourceSerifPro';
    src: url("./fonts/sourcesanspro/SourceSerifPro-Regular.ttf") format("truetype");
  } */

@font-face {
    font-family: 'SpaceMono';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/SpaceMono-Regular.8233fc21.ttf) format("truetype");
}

@font-face {
    font-family: 'Spectral';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Spectral-Regular.a0674d7f.ttf) format("truetype");
}

@font-face {
    font-family: 'WorkSans';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/WorkSans-Regular.5f6c3893.ttf) format("truetype");
}

@font-face {
    font-family: 'Montserrat';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Montserrat-Regular.7c8ccfe4.ttf) format("truetype");
}

@font-face {
    font-family: 'Oswald';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Oswald-Bold.c71e53ff.ttf) format("truetype");
}

@font-face {
    font-family: 'Freehand';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Freehand.926cfec9.ttf) format("truetype");
}

@font-face {
    font-family: 'Bitter';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Bitter-Regular.8db0301b.ttf) format("truetype");
}

@font-face {
    font-family: 'Roboto';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Roboto-Regular.71779d80.ttf) format("truetype");
}

@font-face {
    font-family: 'SourceSansPro';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/SourceSansPro-Regular.73ae3a58.ttf) format("truetype");
}

@font-face {
    font-family: 'OpenSans';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/OpenSans-Regular.17e98b9e.ttf) format("truetype");
}

@font-face {
    font-family: 'ProximaNova-Black';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Proxima-Nova-Black.09718ef7.otf) format("truetype");
}

@font-face {
    font-family: 'ProximaNova-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Proxima-Nova-Bold.3c9eb6f6.otf) format("truetype");
}

@font-face {
    font-family: 'ProximaNova-Light';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Proxima-Nova-Light.a2d04246.otf) format("truetype");
}

@font-face {
    font-family: 'ProximaNova';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Proxima-Nova-Regular.78a4c5bf.otf) format("truetype");
}

@font-face {
    font-family: 'AvenirNext-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/AvenirNext-Bold.e130d613.ttf) format("truetype");
}

@font-face {
    font-family: 'AvenirNext-DemiBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/AvenirNext-DemiBold.ca33239c.ttf) format("truetype");
}

@font-face {
    font-family: 'AvenirNext-Medium';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/AvenirNext-Medium.e72b6526.ttf) format("truetype");
}

@font-face {
    font-family: 'AvenirNext-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/AvenirNextLTPro-Regular.94f2f163.otf) format("truetype");
}

@font-face {
    font-family: 'Inter-Bold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Inter-Bold-slnt=0.3e3cb905.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter-Medium';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Inter-Medium-slnt=0.18b8c68e.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter-Regular';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Inter-Regular-slnt=0.9cd7588f.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Inter-ExtraBold-slnt=0.55189ddf.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url(https://d36jj8y9swlpop.cloudfront.net/static/media/Inter-SemiBold-slnt=0.c6588ec1.ttf) format("truetype");
}

@font-face {
    font-family: 'Palatino-BoldItalic';
    src: local('./fonts/Palatino/Palatino Bold Italic'), local('Palatino-Bold-Italic'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Palatino-BoldItalic.34ddca5b.woff2) format('woff2'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Palatino-BoldItalic.c458e3bc.woff) format('woff'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Palatino-BoldItalic.ff310456.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Arial-Regular';
    src: local('Arial Regular'), local('Arial-Regular'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/ArialMT.2f06d009.woff2) format('woff2'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/ArialMT.e4af816a.woff) format('woff'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/ArialMT.be45eea7.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Arial-Bold';
    src: local('Arial Bold'), local('Arial-Bold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Arial-BoldMT.64cdc6e9.woff2) format('woff2'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Arial-BoldMT.0f724961.woff) format('woff'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Arial-BoldMT.49a674bb.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'PTSerif-Italic';
    src: local('PTSerif-Italic'), local('PTSerif-Italic'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/PTSerif-Italic.a14a89f9.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: local('Gilroy-Regular'), local('Gilroy-Regular'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-regular.00b02679.woff2) format('woff2'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-regular.31d54a4b.woff) format('woff'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-regular.b91b0127.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-SemiBold';
    src: local('Gilroy-SemiBold'), local('Gilroy-SemiBold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-semibold.732e892b.woff2) format('woff2'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-semibold.e38937db.woff) format('woff'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-semibold.67802926.ttf) format('truetype');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: local('Gilroy-Bold'), local('Gilroy-Bold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-bold.0ad1eb91.woff2) format('woff2'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-bold.5fb38b9f.woff) format('woff'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/gilroy-bold.b900325e.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSerif-Bold';
    src: local('NotoSerif-Bold'), local('NotoSerif-Bold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/NotoSerif-Bold.d393a95e.ttf) format('truetype');
}

@font-face {
    font-family: 'NotoSerif-Bold';
    src: local('NotoSerif-Bold'), local('NotoSerif-Bold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/NotoSerif-Bold.d393a95e.ttf) format('truetype');
}

@font-face {
    font-family: 'NunitoSans-SemiBold';
    src: local('NunitoSans-SemiBold'), local('NunitoSans-SemiBold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/NunitoSans-SemiBold.662b8665.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    src: local('Lato-Bold'), local('Lato-Bold'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Lato-Bold.5dd5aa02.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: local('Lato-Regular'), local('Lato-Regular'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Lato-Regular.9919edff.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato-Light';
    src: local('Lato-Light'), local('Lato-Light'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/Lato-Light.bfc37ff1.ttf) format('truetype');
}

@font-face {
    font-family: 'LucidaGrande';
    src: local('LucidaGrande'), local('LucidaGrande'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/LucidaGrandeBold.8f757bf4.ttf) format('truetype');
}

@font-face {
    font-family: 'LucidaGrande';
    src: local('LucidaGrande'), local('LucidaGrande'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/LucidaGrandeBold.8f757bf4.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Bold';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-Bold.852a2b2c.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-ExtraBold';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-ExtraBold.fb0cf087.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-ExtraLight';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-ExtraLight.123f9a49.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Light';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-Light.154308a9.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Medium';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-Medium.f61f14ce.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-Regular';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-Regular.1c328f01.ttf) format('truetype');
}

@font-face {
    font-family: 'BricolageGrotesque-SemiBold';
    src: local('BricolageGrotesque'), local('BricolageGrotesque'),
        url(https://d36jj8y9swlpop.cloudfront.net/static/media/BricolageGrotesque-SemiBold.dfee9cfe.ttf) format('truetype');
}


.Toastify__toast {
    min-height: auto;
    padding: 15px;
    border-radius: 4px;
    font-family: "Avenir Next";
}

.Toastify__toast--success {
    background: #24554e;
}

.Toastify__toast--error {
    background: #cf0101;
}


.button-full {
    width: 100%;
}


a,
div.text,
p {
    word-break: break-word;
}

:root {
    --base: #22272F;
    --primary_90: #153833;
    --primary_80: #24554E;
    --primary_50: #36796F;
    --primary_40: #54938B;
    --primary_20: #7BAEA9;
    --primary_10: #A6C9C5;
    --primary_5: #E8F2F1;
    --greyscale_90: #131F1E;
    --greyscale_60: #444C4B;
    --greyscale_40: #727978;
    --greyscale_30: #A1A5A5;
    --greyscale_20: #D0D2D2;
    --greyscale_10: #E7E9E9;
    --greyscale_5: #F0F2F2;
    --greyscale_0: #FFFFFF;
    --blackscale_80: rgba(19, 31, 30, 0.8);
    --blackscale_70: rgba(19, 31, 30, 0.7);
    --blackscale_60: rgba(19, 31, 30, 0.6);
    --blackscale_40: rgba(19, 31, 30, 0.4);
    --blackscale_20: rgba(19, 31, 30, 0.2);
    --blackscale_10: rgba(19, 31, 30, 0.1);
    --blackscale_5: rgba(19, 31, 30, 0.05);
    --light: #EDF0EF;
    --dark_danger: #A61C23;
    --mid_dange: #F8C5C5;
    --danger: #D12D36;
    --light_danger: #FFF1F1;
    --success: #379552;
    --light_success: #DEFBE6;
    --alarm: #D3AC1E;
    --light_alarm: #FFF8E1;
    --link_active: #3060BD;
    --link_visited: #8830BD;
    --scale_90: #131F1E;
    --pink_1: #FEE1F2;
    --additional_error: #D12D36;
    --additional_success: #379552;
    --additional_danger: #D3AC1E;
}

.text_with_image_section_content {
    width: 100%;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

.ql-bubble,
.ql-editor {
    font-size: inherit !important;
    font-family: inherit !important;
    text-align: inherit !important;
    line-height: inherit !important;
}

.ql-editor {
    padding: 0 !important;
    overflow-y: visible !important;
}

.ql-editor:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
}

.ql-bubble .ql-tooltip {
    z-index: 99999;
    line-height: 1;
}

.ql-toolbar {
    line-height: 1;
    height: 48px;
    display: flex;
    align-items: center;
}

.ql-bubble .ql-editor a {
    text-decoration: none;
}


.ql-bubble .ql-tooltip-editor input[type=text] {
    position: relative;
}

.ql-bubble .ql-picker {
    height: auto;
}

.ql-bubble .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: -7px;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
    max-height: 130px;
    scrollbar-width: thin;
    overflow-y: auto;

}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: rgb(226, 226, 226);
}

.ql-tooltip {
    width: 290px !important;
}


.quillButton .ql-tooltip {
    max-width: 290px !important;
    min-width: 103px !important;
    width: auto !important;
}

.ql-bubble .ql-picker.ql-font {
    width: 75px;
}

.ql-bubble .ql-picker-label::before {
    white-space: nowrap;
    overflow: hidden;
    width: 50px;
    text-overflow: ellipsis;
    position: relative;
    top: 2px;
    font-size: 14px;
}

.ql-bubble .ql-toolbar .ql-formats {
    margin-right: 2px;
}
.SectionComponents {
    width: 100% !important;
}

.SectionComponents.menu {
    padding-bottom: 16px;
}

.SectionComponents.menu .item {
    padding: 4px 16px;
}

.SectionComponents.menu .item:before {
    display: none;
}

.SectionComponents.menu .SectionComponents_name {
    padding: 10px 12px;
    border: 1px solid #dfe5eb;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Avenir Next";

}

.SectionComponents.menu .SectionComponents_name .component_icon_up {
    display: none;
}

.SectionComponents.menu .SectionComponents_name .component_name {
    color: #3f4f65;
}

.SectionComponents.menu .item img {
    width: 16px !important;
}

.SectionComponents.menu .item .header {
    padding: 10px !important;
}

.SectionComponent img {
    border: none !important;
}

.SectionComponents .SectionComponent {
    /* background-color:#f7f7f7; */
    padding: 16px;
}

.SectionComponent {
    transition: 1s all;
}

#section_remove_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.link_delete {
    box-sizing: border-box;
    width: 90%;
    box-sizing: border-box;
    background: #24554e !important;
    color: #fff !important;
    margin: 12px 16px !important;
}

/* .sidebarContainer .mce-notification-inner {
    display:none!important;
}

body .mce-widget.mce-notification.mce-notification-warning.mce-has-close.mce-in {
    display: none!important;
} */




.add-section-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 16px;
    background: transparent;
    border-top: 0.5px solid #e4e4e4;
}

.add-section-left-side {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.add-section-items:hover {
    background-color: #e5e5e5;
    cursor: pointer;
}
.add-section-name-and-description {
    display: flex;
    flex-direction: column; 
    padding: 0 15px; 
    color: #4f5863; 
}
.add-section-name {
    font-size:16px;
    font-weight: bold;
    padding-bottom:10px; 
}
.add-section-name-and-description {
    font-size: 12px;
}
.view_icons{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}
.desktop{
    display: flex;
    align-items: flex-end;
}
.tablet, .phone {
    display: flex;
}
.view_icons img {
    height: 16px;
}

/* .menu-item:hover{
    background-color: rgba(124, 183, 64, 0.1);
    border: 1px solid #7cb740;
    cursor: pointer;
} */

.all-menus {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px;
}

.draggable-menus {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    color: #3f4f65;
}

.menu-item-settings:hover {
    background-color: #7cb7401a;
    border: 1px solid #24554e;
    cursor: pointer;
}

.menu-item:hover .menu-item-left-drag {
    display: flex;
    align-items: center;
}

.menu-item-right i:hover {
    cursor: pointer;
}

.menu-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
}

.menu-item-left {
    display: flex;
    align-items: center;
}

.menu-item-left input {
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    line-height: 148%;
    border: none;
    outline: none;
}

.menu-item-left-drag {
    width: 16px !important;
    height: 12px !important;
    margin-right: 10px;
    display: none;
}

.menu-item-left img {
    width: 16px !important;
    height: 16px;
}

.menu-item-right i {
    padding-top: 5px;
    height: 20px;
}

.menu-item-right i:last-child {
    margin-right: 0;
}

.menu-item-right-buttons {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.menu-item {
    /* width: 100%!important;
    border: 1px solid #c2cedb;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 49px;*/
}

.menu-item-settings {
    width: 100% !important;
    border: 1px solid #c2cedb;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 49px;
}

.menu-item-draggable-disabled {
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 49px;
}

.menu-item-draggable {
    background-color: #fff;
    border-radius: 4px;
    height: 49px;
}

.menu-item-draggable-disabled:hover {
    background-color: rgba(124, 183, 64, 0.1);
    border: 1px solid #24554e;
    cursor: pointer;
}

.menu-item-draggable:hover {
    cursor: pointer;
}

.menu-footer-item {
    width: 100%;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    -webkit-user-select: none;
            user-select: none;
}

.menu-footer-item .menu-item-left {
    display: flex;
    align-items: center;
}

.menu-item-right-actions {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.textBasic {
   font-style: normal;
   font-stretch: normal;
   position: relative;
   letter-spacing: normal;
   -webkit-touch-callout: text;
   -webkit-user-select: text;
   user-select: text;
}
.textBasic div{
   padding: 5px;
}
.mini-text{
   font-weight: 500;
   font-size: 12px;
   line-height: 150%;
   font-feature-settings: 'tnum' on, 'lnum' on;
   color: #727978;
   position: absolute;
   margin-left: 4px;
   top: 0%;
   white-space: nowrap;
}

.textBasic_type_regular {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 168%;
   color: var(--greyscale_60);
}

.textBasic_type_select {
   font-weight: 400;
   line-height: 132%;
   color: var(--greyscale_90);
}
.textBasic_type_select_large {
   font-weight: 400;
   line-height: 168%;
   color: var(--greyscale_90);
}
.textBasic_type_regularDefault {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 168%;
   color: var(--greyscale_90);
}

.textBasic_type_regularDefaultGrey {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 168%;
   color: #727978;
}

.textBasic_type_regularDefaultGrey145 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 145%;
   color: #727978;
}

.textBasic_type_regularDefault145 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 145%;
   color: var(--greyscale_90);
}


.textBasic_type_regularDefaultSmallX {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 132%;
   color: var(--greyscale_90);
}
.textBasic_type_regularDefaultSmall {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 132%;
   color: var(--greyscale_90);
}
.textBasic_type_regularLarge {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 150%;
   color: var(--greyscale_90);
}

.textBasic_type_medium {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 133%;
   color: #131f1e;
}
.textBasic_type_regular148 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 148%;
   color: var(--greyscale_90);
}
.textBasic_type_regular160 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 160%;
   color: var(--greyscale_90);
}
.textBasic_type_mediumLarge {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 168%;
   color: var(--greyscale_90);
}

.textBasic_type_mediumLargeGrey {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 168%;
   color: #727978;
}

.textBasic_type_mediumSmall {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 148%;
   color: var(--greyscale_90);
}

.textBasic_type_mediumXSmall {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 140%;
   color: var(--greyscale_90);
}


.textBasic_type_medium160 {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 160%;
   color: var(--greyscale_90);
}

.textBasic_type_medium150{
   font-family: "Roboto";
   font-weight: 500;
   line-height: 150%;
   color: var(--greyscale_90);
}

.textBasic_type_medium153 {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 153%;
   color: var(--greyscale_90);
}
.textBasic_type_bold {
   font-family: "Roboto";
   font-weight: 700;
   line-height: 153%;
   color: var(--greyscale_90);
}
.textBasic_type_bold100 {
   font-family: "Roboto";
   font-weight: 700;
   line-height: 100%;
   color: var(--greyscale_90);
}


.textBasic_type_regularMin {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 130%;
   color: #131f1e;
}

.textBasic_size_xlarge {
   font-size: 20px;
}

.textBasic_size_large {
   font-size: 18px;
}

.textBasic_size_medium {
   font-size: 16px;
}

.textBasic_size_small {
   font-size: 14px;
}

.textBasic_size_xsmall {
   font-size: 12px;
}

.textBasic_size_xx_small {
   font-size: 10px;
}

.textBasic_size_xxlarge {
   font-size: 24px;
}

.textBasic_size_size_28 {
   font-size: 28px;
}

.textBasic_size_size_40 {
   font-size: 40px;
}

.text-with-icon {
   display: flex;
   grid-gap: 11px;
   gap: 11px;
   align-items: center;
}

.toggle {
   cursor: pointer;
   display: inline-block;
}
.toggle-switch {
   display: inline-block;
   background: var(--greyscale_20);
   border-radius: 16px;
   width: 42px;
   height: 26px;
   position: relative;
   vertical-align: middle;
   transition: background 0.25s;
}
.toggle-switch:active {
   background: var(--greyscale_30);
}
.toggle-switch:before, .toggle-switch:after {
   content: "";
}
.toggle-switch:before {
   display: block;
   background: #fff;
   border-radius: 50%;
   width: 22px;
   height: 22px;
   position: absolute;
   top: 2px;
   left: 4px;
   transition: left 0.25s;
}
.toggle-checkbox {
   position: absolute;
   visibility: hidden;
}
.switch_text {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   margin-bottom: -4px;
   display: flex;
   align-items: center;
   font-size: 14px;
   line-height: 168%;
   font-feature-settings: "tnum" on, "lnum" on;
   color: var(--greyscale_90);
}
.switch_tooltip {
   margin-left: -4px;
}
.switch {
   display: flex;
   align-items: center;
}
.toggle_disabled_false {
   background: var(--greyscale_10);
}
.toggle_disabled_false:active {
   background: var(--greyscale_10);
}
.toggle_disabled_true {
   background: var(--greyscale_40) !important;
}
.toggle_disabled_true:active {
   background: var(--greyscale_40) !important;
}
.toggle_disabled_true:before {
   background: var(--greyscale_10) !important;
}
.switch__medium .toggle-switch {
   width: 30px;
   height: 20px;
   position: relative;
   vertical-align: middle;
   transition: background 0.25s;
}
.switch__medium .toggle-switch:active {
   background: var(--greyscale_30);
}
.switch__medium .toggle-switch:before, .switch__medium .toggle-switch:after {
   content: "";
}
.switch__medium .toggle-switch:before {
   display: block;
   background: #fff;
   border-radius: 50%;
   width: 16px;
   height: 16px;
   position: absolute;
   top: 2px;
   left: 4px;
   transition: left 0.25s;
}
.switch__medium .toggle-switch-active {
   background: var(--primary_80);
}
.switch__medium .toggle-switch-active:active {
   background: var(--primary_90);
}
.switch__medium .toggle-switch-active:before {
   left: 12px;
}
.switch {
   display: flex;
   align-items: center;
   grid-gap: 12px;
   gap: 12px;
}

button {
    border: 0;
}

@media all and (min-width: 320px) and (max-width: 375px) {
    .landing_1_header_buttons,
    .landing_2_header_buttons,
    .landing_3_header_buttons {
        font-size: 8px!important;
        padding: 0 5px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 0 8px!important;
        font-size: 10px!important;
        line-height: 1.4;
    }
    .landing_1_section_5_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_2_section_2_content_button,
    .landing_6_section_5_content_button,
    .landing_7_section_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 10px 20px!important;
        font-size: 12px!important;
    }
    .landing_3_section_5_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_4_header_buttons,
    .landing_5_header_buttons {
        font-size: 10px!important;
        padding: 0 10px !important;
    }
    .landing_4_section_2_content_button,
    .landing_4_section_6_content_button {
        padding: 10px 20px!important;
        font-size: 14px!important;
    }

    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button,
    .landing_6_header_button {
        padding: 7px 15px!important;
        font-size: 12px!important;
    }
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 10px!important;
        padding: 5px 10px !important;
    }
}

@media all and (min-width: 376px) and (max-width: 417px) {
    .landing_1_header_buttons,
    .landing_2_header_buttons,
    .landing_3_header_buttons,
    .landing_5_header_buttons {
        font-size: 9px!important;
        padding: 0 6px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 0 12px!important;
        font-size: 10px!important;
        line-height: 1.4;
    }
    .landing_1_section_5_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_2_section_2_content_button,
    .landing_6_section_5_content_button,
    .landing_7_section_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 10px 20px!important;
        font-size: 12px!important;
    }
    .landing_3_section_5_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_4_header_buttons {
        font-size: 11px!important;
        padding: 0 15px !important;
    }
    .landing_4_section_2_content_button,
    .landing_4_section_6_content_button,
    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button,
    .landing_6_header_button {
        padding: 10px 20px!important;
        font-size: 14px!important;
    }
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 10px!important;
        padding: 5px 15px !important;
    }
}

@media all and (min-width: 418px) and (max-width: 572px) {
    .landing_1_header_buttons {
        font-size: 10px!important;
        padding: 5px 9px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 0 16px!important;
        font-size: 10px!important;
        line-height: 1.4;
    }
    .landing_2_header_buttons,
    .landing_3_header_buttons,
    .landing_5_header_buttons,
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 10px!important;
        padding: 5px 8px !important;
    }
    .landing_4_header_buttons {
        font-size: 12px!important;
        padding: 5px 20px !important;
    }
    .landing_2_section_2_content_button,
    .landing_6_section_5_content_button,
    .landing_7_section_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 10px 20px!important;
        font-size: 14px!important;
    }
    .landing_3_section_5_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_4_section_2_content_button,
    .landing_4_section_6_content_button,
    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button {
        padding: 15px 30px!important;
        font-size: 16px!important;
    }
}

@media all and (min-width: 573px) and (max-width: 768px) {
    .landing_1_header_buttons,
    .landing_2_header_buttons,
    .landing_3_header_buttons,
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 12px!important;
        padding: 5px 10px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 2px 25px!important;
        font-size: 12px!important;
        line-height: 1.4;
    }
    .landing_3_section_5_content_button,
    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 15px 30px!important;
        font-size: 16px!important;
    }
    .landing_4_header_buttons {
        font-size: 14px!important;
        padding: 5px 35px !important;
    }
    .landing_5_header_buttons {
        font-size: 14px!important;
        padding: 5px 25px !important; 
    }
}

@media all and (min-width: 769px) and (max-width: 910px) {
    .landing_9_header_buttons {
        font-size: 20px!important;
        padding: 10px 15px !important;
    }
}

.btnBasic {
   font-family: "Roboto";
   font-weight: 400;
   font-size: 14px;
   border-radius: 12px;
   border: none;
   letter-spacing: 1%;
   text-align: center;
   width: auto;
   cursor: pointer;
   -webkit-user-select: none;
           user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   color: var(--greyscale_0);
   padding: 13px 16px;
   grid-gap: 8px;
   gap: 8px;
   white-space: nowrap;
}
.btnBasic div {
   display: flex;
   align-items: center;
   justify-content: center;
}
.btnBasic svg path {
   fill: var(--greyscale_0);
}
.btnBasic_theme_change {
   background: rgba(19, 31, 30, 0.4);
   border: 1px solid #fff;
}
.btnBasic_theme_canva {
   background: #20c4cb;
   width: 100%;
   transition: 0.3s ease;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 7px 16px 9px;
   grid-gap: 4px;
   gap: 4px;
   font-weight: 400;
   letter-spacing: 0.01em;
   font-size: 14px;
   line-height: 132%;
}
.btnBasic_theme_primary {
   background-color: var(--primary_80);
   border: 1px solid;
   transition: 0.3s ease;
}
.btnBasic_theme_primary:hover {
   background-color: var(--blackscale_70);
}
.btnBasic_theme_primary:active {
   background-color: var(--blackscale_80);
}
.btnBasic_theme_primary_disabled {
   opacity: 0.4;
}
.btnBasic_theme_primary_disabled:hover {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}
.btnBasic_theme_primary_disabled:active {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}
.btnBasic_theme_primary_disabled svg path {
   fill: var(--blackscale_40);
}
.btnBasic_theme_more {
   border: 1px solid #24554e;
   border-radius: 12px;
   background-color: inherit;
   color: #24554e;
   width: 69px;
   height: 32px;
   font-size: 12px;
   padding: 0 !important;
   box-sizing: border-box;
   min-width: 69px;
}
.btnBasic_theme_error {
   border: 1px solid #d12d36;
   border-radius: 12px;
   background-color: inherit;
   color: #d12d36;
   height: 32px;
   font-size: 12px;
   box-sizing: border-box;
   min-width: 136px;
   width: 136px;
}
.btnBasic_theme_filter_true {
   background: #e7e9e9;
}
.btnBasic_theme_secondary {
   color: var(--primary_80);
   border: 1px solid var(--primary_80);
   background-color: inherit;
   transition: 0.3s ease;
}
.btnBasic_theme_secondary svg path {
   fill: var(--primary_80);
}
.btnBasic_theme_secondary:hover {
   background-color: var(--blackscale_10);
}
.btnBasic_theme_secondary:active {
   background-color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled {
   background-color: var(--blackscale_5);
   border-color: var(--blackscale_20);
   color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled:hover {
   background-color: var(--blackscale_5);
   border-color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled:active {
   background-color: var(--blackscale_5);
   border-color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled svg path {
   fill: var(--blackscale_20);
}
.btnBasic_theme_red {
   background: #d12d36;
   border-radius: 12px;
   transition: 0.3s ease;
   color: #fff;
}
.btnBasic_theme_red svg path {
   fill: #fff;
}
.btnBasic_theme_tertiary {
   background-color: inherit;
   border: none;
   color: var(--greyscale_90);
}
.btnBasic_theme_tertiary svg path {
   fill: var(--greyscale_90);
}
.btnBasic_theme_tertiary:hover {
   color: var(--greyscale_40);
}
.btnBasic_theme_tertiary:hover svg path {
   fill: var(--greyscale_40);
}
.btnBasic_theme_tertiary:active {
   color: var(--greyscale_60);
}
.btnBasic_theme_tertiaryGreen {
   background-color: inherit;
   border: none;
   color: var(--primary_80);
}
.btnBasic_theme_tertiaryGreen svg path {
   fill: var(--primary_80);
}
.btnBasic_theme_tertiaryGreen:hover {
   color: var(--greyscale_40);
}
.btnBasic_theme_tertiaryGreen:hover svg path {
   fill: var(--greyscale_40);
}
.btnBasic_theme_tertiaryGreen:active {
   color: var(--greyscale_60);
}
.btnBasic_size_small {
   padding: 8px 12px;
}
.btnBasic_size_medium {
   padding: 9px 16px;
}
.btnBasic_size_large {
   min-height: 44px;
   padding: 12px 16px;
   max-height: 44px;
}
.btnBasic_size_xsmall {
   padding: 10px 12px;
   font-size: 12px;
   line-height: 132%;
   max-height: 36px;
}
.btnBasic_size_large120 {
   min-height: 44px;
   padding: 12px 16px;
   max-height: 44px;
   min-width: 120px;
}
.btnBasic_size_small63 {
   padding: 13px 16px;
   width: -webkit-fit-content;
   width: -moz-fit-content;
   width: fit-content;
   max-height: 44px;
}
.btnBasic_size_large50 {
   padding: 13px 16px;
}
.btnBasic_theme_filter {
   background: #fff;
   display: flex;
   align-items: center;
   padding: 8px 13px;
   font-weight: 400;
   font-size: 12px;
   line-height: 132%;
   grid-gap: 8px;
   gap: 8px;
   min-height: 36px !important;
   color: #131f1e;
   min-width: -webkit-min-content;
   min-width: min-content;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
}
.btnBasic_theme_filter svg path {
   fill: #131f1e;
}

.triangle{position:absolute;transform:translate(50%, -50%);cursor:pointer}.triangle_isOpen_true{border-left:6px solid transparent;border-right:6px solid transparent;border-top:7px solid #3f4f65;right:6px;top:25%}.triangle_isOpen_false{border-left:7px solid #3f4f65;border-top:6px solid transparent;border-bottom:6px solid transparent;right:40px;top:50%}.triangleSvg_isOpen_true{transform:rotate(-90deg)}.triangleSvg_isOpen_false{transform:rotate(90deg)}.down_isOpen_true{transform:rotate(-90deg)}

.delete__modal {
    z-index: 99999999;
   position: absolute;
}
.delete__modal__background {
   background: rgba(19, 31, 30, 0.4);
   width: 100%;
   height: 100vh;
   position: fixed;
   top: 0;
   z-index: 99999999;
   left: 0;
}
.delete__modal__content {
   background: #fff;
   border-radius: 12px;
   padding: 40px;
   z-index: 99999999;
   display: flex;
   flex-direction: column;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   grid-gap: 64px;
   gap: 64px;
   align-items: flex-end;
}
.delete__modal__content__span {
   max-height: 300px;
   overflow-y: auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
   scrollbar-width: thin;
   box-sizing: border-box;
}
.delete__modal__content__span::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}
.delete__modal__content__span::-webkit-scrollbar {
   width: 5px;
   height: 5px;
   background-color: transparent;
}
.delete__modal__content__span::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   background-color: #e2e2e2;
}
.delete__modal__content__footer {
   display: flex;
   justify-content: flex-end;
   grid-gap: 16px;
   gap: 16px;
}

.triggle__popover {
   padding: 4px 0px;
   background: #fff;
   border: 1px solid rgba(19, 31, 30, 0.1);
   box-shadow: 0px 4px 10px rgba(19, 31, 30, 0.1), 1px 2px 2px rgba(19, 31, 30, 0.04);
   border-radius: 12px;
}
.triggle__popover__flex {
   display: flex;
   flex-direction: column;
}
.triggle__popover__item {
   cursor: pointer;
   display: flex;
   align-items: center;
   width: 100%;
   padding: 12px 16px;
   grid-gap: 12px;
   gap: 12px;
   background: #fff;
   border-top-right-radius: 12px;
}
.triggle__popover__item__border {
   border-top: 1px solid #e7e9e9;
   border-top-right-radius: 0;
}
.triggle__popover__item__trash path {
   fill: #d12d36;
}
.triggle__popover__item__trash span {
   color: #d12d36;
}
.triggle__popover__item__toggleOffM path {
   fill: #d12d36;
}
.triggle__popover__item__toggleOffM span {
   color: #d12d36;
}
.drop__triggle__icon {
   cursor: pointer;
   display: flex;
   align-items: center;
}
.triggle__popover__one {
   padding: 4px 0px;
   background: #fff;
   border: 1px solid rgba(19, 31, 30, 0.1);
   box-shadow: 0px 4px 10px rgba(19, 31, 30, 0.1), 1px 2px 2px rgba(19, 31, 30, 0.04);
   display: flex;
   border-radius: 12px;
   align-items: center;
   justify-self: center;
}
.triggle__popover__one .triggle__popover__flex {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-self: center;
}
.triggle__popover__one .triggle__popover__item {
   cursor: pointer;
   display: flex;
   align-items: center;
   padding: 7px 16px;
   grid-gap: 12px;
   gap: 12px;
   background: #fff;
}
.triggle__popover__one .triggle__popover__item__toggleOffM path {
   fill: #d12d36;
}
.triggle__popover__one .triggle__popover__item__toggleOffM span {
   color: #d12d36;
}
.MuiPaper-rounded {
   border-radius: 12px !important;
}

.iconButton {
   background-color: inherit;
   border: none;
   padding: 4px;
   cursor: pointer;
   box-sizing: border-box;
   border-radius: 8px;
   transition: 0.3s ease-in-out;
   display: flex;
   align-items: center;
}
.iconButton__light:hover {
   background-color: #e8f2f1;
}
.iconButton__light:active {
   background: #a6c9c5;
}
.iconButton__light path {
   fill: #24554e;
}
.iconButton__primary {
   border: 1px solid #24554e;
}
.iconButton__delete:hover {
   background: #fff1f1;
}
.iconButton__delete:active {
   background: #f8c5c5;
}
.iconButton__delete path {
   fill: #d12d36;
}
.iconButton__delete__border {
   border: 1px solid #d12d36;
}
.iconButton__light__border {
   border: 1px solid #24554e;
}
.iconButton__disabled {
   background: rgba(19, 31, 30, 0.1);
   border: 1px solid rgba(19, 31, 30, 0.4);
   box-sizing: border-box;
}
.iconButton__disabled path {
   fill: rgba(19, 31, 30, 0.4);
}

.editor__top{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    border: 1px solid #E7E9E9;
}


.editor__top__wrapper{
    display: flex;
    align-items: center;
    padding: 18px 24px;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 1024px) {
    .editor__top__wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 20px;
        gap: 20px;
    }
}

.editor__top__wrapper__sizes{
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.editor__top__wrapper__sizes__button{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.editor__top__wrapper__sizes__active{
    border: 1px solid #24554E;
    border-radius: 8px;
   
}

.editor__top__wrapper__sizes__active path{
    fill: #24554E;
}

.editor__top__wrapper__actions{
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
}

.editor__top__wrapper__actions__undo{
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
}

.editor__top__wrapper__actions__button{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
}

.editor__top__wrapper__actions__line{
    height: 24px;
    width: 1px;
    margin-right: 8px;
    background-color: #D9D9D9;
}

.editor__top__wrapper__left{
    margin-left: 32px;
}


.mobile__buttons {
    display: none;
}

@media (max-width: 1024px) {
    .mobile__buttons {
        display: flex;
    }
}

.desktop__buttons {
    display: flex;
}

@media (max-width: 1024px) {
    .desktop__buttons {
        display: none;
    }

    .mobile__buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile__buttons__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 20px;
        gap: 20px;
    }

    .editor__top__wrapper__actions__line {
        display: none;
    }
}
.checkbox-toggle-content {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.checkbox-toggle-content .checkbox-toggle-label {
    color: #3f4f65;
    font-family: 'AvenirNext-DemiBold';
}

.checkbox-toggle .checkbox,
.dn,
.up {
    display: inline-block !important;
    vertical-align: middle;
}

.checkbox-toggle .ui.checkbox input,
.checkbox-toggle .ui.toggle.checkbox label:before {
    padding-top: 2px;
    font-size: xx-small;
    content: attr(data-content)
}

.checkbox-toggle .ui.checkbox input,
.checkbox-toggle .ui.toggle.checkbox label.up:before {
    padding-left: 8px;
    padding-top: 2px;
    color: white;
    display: flex;
    align-items: center;
}

.checkbox-toggle .ui.checkbox input,
.checkbox-toggle .ui.toggle.checkbox label.dn:before {
    padding-left: 24px;
    padding-top: 2px;
    color: black;
    display: flex;
    align-items: center;
}

.checkbox-toggle .ui.toggle.checkbox label:after {
    transition: background 0.3s ease 0s, right 0.3s ease 0s
}

.checkbox-toggle .ui.toggle.checkbox input:checked~.box:after,
.checkbox-toggle .ui.toggle.checkbox input:checked~label:after {
    left: 2.0rem;
}

.checkbox-toggle .ui.toggle.checkbox input:checked+label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:checked~.box:before,
.checkbox-toggle .ui.toggle.checkbox input:checked~label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:checked~.box:before,
.checkbox-toggle .ui.toggle.checkbox input:checked~label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:focus:checked~.box:before,
.checkbox-toggle .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:checked+label:after {
    transition: background 0.3s ease 0s, right 0.3s ease 0s
}

.checkbox-toggle .ui.checkbox input,
.ui.toggle.checkbox label:before {
    padding-top: 2px;
    font-size: xx-small;
    content: attr(data-content);
    width: 3.5rem;
}

.SectionComponents {
    width: 100% !important;
}

.SectionComponents__top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
}

.SectionComponents__top__left {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    align-items: center;
}

.SectionComponents__top__icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.SectionComponents.menu {
    padding-bottom: 16px;
}

.Section-Component {}

.SectionComponents.menu .item {
    padding: 8px 0;
}

.SectionComponents.menu .item:before {
    display: none;
}

.SectionComponents.menu .SectionComponents_name {
    padding: 10px 12px;
    border: 1px solid #dfe5eb;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Avenir Next";

}

.SectionComponents.menu .SectionComponents_name .component_icon_up {
    display: none;
}

.SectionComponents.menu .SectionComponents_name .component_name {
    color: #3f4f65;
}

.SectionComponents.menu .item img {
    width: 16px !important;
}

.SectionComponents.menu .item .header {
    padding: 10px !important;
}

.SectionComponent img {
    border: none !important;
}

.SectionComponents .SectionComponent {
    /* background-color:#f7f7f7; */
    padding: 16px;
}

.SectionComponent {
    transition: 1s all;
}

#section_remove_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

/* .sidebarContainer .mce-notification-inner {
    display:none!important;
}

body .mce-widget.mce-notification.mce-notification-warning.mce-has-close.mce-in {
    display: none!important;
} */

.maincomponent {
    margin: 5px;
    padding-bottom: 16px;
}

.SectionComponents.menu .SectionComponents_name {
    display: flex;
    align-items: flex-start;
    padding: 8px 12px;
    border: none !important;
    grid-gap: 12px;
    gap: 12px;
    transition: 0.3s all;
    align-items: center;
    justify-content: space-between;
}

.SectionComponents.menu .SectionComponents_name_active {
    background: #E8F2F1;
    border-radius: 8px;
}

.SectionComponents.menu .SectionComponents_name_icon {
    transition: 0.3s all;
}


.item {
    background-color: inherit !important;
}

.SubSectionComponent {
    background-color: #FFF !important;
}

.item-flex {
    display: flex !important;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.text_column_width_new {
    border: 1px solid #e7e9e9 !important;
    padding: 5px;
    width: -webkit-min-content !important;
    width: min-content !important;
    max-width: 67px;
    height: 24px;
    border-radius: 8px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 168%;
    color: #131f1e;
}

.text_column_content {
    display: flex;
    align-items: center;
    margin-top: 8px;
 }

.text_column_content input{
    background: #e7e9e9;
    background-image: linear-gradient(#7baea9,#7baea9);
    border-radius: 2px;
    height: 2px;
}

.text_column_content input::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
  }
  .text_column_content input::-ms-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
  } 

  .text_column_content input::-moz-range-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
  }
 .text_column_name {
    margin-top: 16px;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
    color: #3f4f65;
    font-family: 'AvenirNext-DemiBold';
 }

 .text_column_top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
 }input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background:  #E7E9E9;; /* Otherwise white in Chrome */
    background-image: linear-gradient( #7BAEA9,#7BAEA9);
    border-radius: 2px;
    height: 2px;
    background-repeat: no-repeat;
    background-size: 0% 100%;
  }
 
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
 
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
 
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
 
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
 
 
  /* Special styling for WebKit/Blink */
 input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
 
  }
 
  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
 
  }
 
  /* All the same stuff for IE */
  input[type=range]::-ms-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
 
  }

  .text_column_content{
    margin-bottom: 12px;
  }
.size-form {
    width: 100%;
}
.ImageEditable {
    margin-top: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
}

.ImageEditable > p {
    font-size: 14px;
    font-weight: bold;
}

.ImageEditable > img {
    width: 93%; 
}

.sidebar_img {
    border: 1px solid rgba(0,0,0,.1);
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    justify-content: center;
    background-color: #f0f6ff;
    padding: 15px;
}

.default_img img {
    filter:brightness(0) invert(1);
    padding: 15px 0!important;
}
.ImageEditable > input {
    display: none;
}

.ImageEditable > .button {
    width: 93%;
}

.sizing {
    width: 93%;
    margin:5px 0;
}
  .uploadcare_widget {
      display: flex;
      justify-content: center;
      align-items: center;
  }


  .uploadcare--widget__button_type_open:focus {
      outline: none;
  }

  .uploadcare--widget__button_type_open {
      display: none;
  }

  .recommended__text {
      color: rgb(114, 121, 120);
      margin-top: 8px;
      font-size: 12px;
      font-weight: bold;
      line-height: 148%;
  }

  .uploadcare--widget__button_type_cancel {
      display: none;
  }

  #uploadcare--widget__text {
      display: none;
  }

  .uploadcare--progress_type_canvas {
      color: #24554e;
      margin: 10px 0 20px 0;
      display: none;
  }

  .upcare-upload-btn {
      display: flex;
      flex-direction: column;
  }

  .upcare-upload-btn button {
      width: 100%;
  }

  .uploadProgress__content {
      margin-bottom: 10px;
      margin-top: 10px
  }

  .uploadProgress__content .cancel_btn {
      display: flex;
      align-items: center;
  }

  .uploadProgress__content svg {
      position: relative;
      left: 6px;
      cursor: pointer;
  }


  .uploadProgress__content .uploadProgress {
      width: 100%;
      background-color: #ddd;
      border-radius: 15px;
      margin-top: 5px;
  }


  .uploadProgress__content .uploadBar {
      width: 0%;
      height: 12px;
      background-color: #24554e;
      border-radius: 15px;
  }

  .uploadProgress__content .upload_text {
      color: #8a94a2;
      font-size: 16px;
      line-height: 1.5;
      font-family: 'AvenirNext-Medium';
  }

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}
.ReactCrop:focus {
  outline: none;
}
.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  display: block;
  max-width: 100%;
  touch-action: manipulation;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: manipulation;
  border: 1px solid;
  border-image-source: url('data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==');
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: calc(100% / 3);
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: calc(100% / 3 * 2);
}
.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: calc(100% / 3);
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: calc(100% / 3 * 2);
}
.ReactCrop__drag-handle {
  position: absolute;
}
.ReactCrop__drag-handle::after {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-n::after {
  top: 0;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-e::after {
  right: 0;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-s::after {
  bottom: 0;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop .ord-w::after {
  left: 0;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n, .ReactCrop .ord-e, .ReactCrop .ord-s, .ReactCrop .ord-w {
    display: none;
 }
  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
 }
}

.uploadModalWrapper {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.close__button__wrapper {
    position: absolute;
    top: 3px;
    right: 5px;
}

.uploaded__image__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.uploaded__image__wrapper .button__wrapper {
    position: absolute;
}

.uploaded__image__wrapper input {
    display: none;
}

.uploaded__image__wrapper img {
    width: 100%;
    border-radius: 12px;
}

.uploadModalWrapper.empty {
    width: 100%;
    margin-top: 12px;
    height: 226px;
    border: 1px solid #E7E9E9;
    border-radius: 12px;
}

.uploadModalWrapper.centered {
    justify-content: center;
}

.uploadModalWrapper:focus {
    border: none;
    outline: none;
}

.uploadModalWrapper .uploadArea {
    display: flex;
    justify-content: center;
    height: 100%;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
}

.uploadModalWrapper .uploadArea.dragging {
    border: 1px dashed rgba(63, 79, 101, 0.6);
}

.uploadModalWrapper .cropArea {
    max-height: calc(100% - 80px);
    width: 100%;
}

.uploadModalWrapper .dragInnerFooter {
    margin-top: 24px;
}

.uploadArea input {
    display: none !important;
}

.uploadArea {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    flex-direction: column;
}

.uploadModalClose {
    position: absolute;
    top: 40px;
    right: 40px;
}

.uploadModalClose :hover {
    cursor: pointer;
}

.uploadModalClose svg {
    width: 32px;
    height: 32px;
}

.originalImage {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.full-uploadprogress {
    width: 100%;
}

.full-uploadprogress .uploadCont_wrapper {
    width: 100%;
}

.cropAreaImage {
    display: flex;
    justify-content: center;
}

.cropAreaFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
}

.marginTop {
    margin-top: 16px;
}

.cropRatioText {
    display: flex;
    align-items: center;
}

.button_success_custom {
    background-color: #24554e !important;
    color: #fff !important;
}

.button_primary_custom {
    border: 2px solid #006dff !important;
    font-size: 16px !important;
    background-color: #fff !important;
    color: #006dff !important;
    font-family: 'AvenirNext-Bold';
    width: 100%;
}

.dragndropImage {
    text-align: center;
}

.dragndroptext {
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    word-break: break-word;
    -webkit-hyphens: auto;
            hyphens: auto;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    -webkit-user-select: none;
            user-select: none;
    font-family: 'AvenirNext-Bold';
    font-weight: 900;
    font-size: 20px;
    color: rgb(63, 79, 101);
}

.cropbutton {
    min-width: 100px !important;
}

.modal__new {
   position: absolute;
}
.modal__new__background {
   background: rgba(19, 31, 30, 0.4);
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 9999;
   height: 100vh;
}
.modal__new__content {
   position: fixed;
   top: 50%;
   left: 50%;
   background: #fff;
   border-radius: 12px;
   padding: 20px;
   z-index: 9999;
   transform: translate(-50%, -50%);
   min-width: 400px;
}

.spacingHeader {
    color: rgb(151, 151, 151);
    font-size: 13px;
    line-height: 1.43;
    font-family: 'AvenirNext-DemiBold';
}


.SelectItem {
   position: relative;
   display: inline-block;
   display: flex;
   width: 100%;
   flex-direction: column;
   grid-gap: 8px;
   gap: 8px;
}
.SelectItem .selectItem__label {
   display: flex;
   justify-content: space-between;
}
.SelectItem__options {
   overflow-y: auto;
   position: absolute;
   background-color: white;
   width: 100%;
   z-index: 9999;
   top: 100%;
   border-radius: 12px;
   z-index: 1000;
   border: 1px solid #e7e9e9;
   max-height: 300px;
   scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
   scrollbar-width: thin;
   box-sizing: border-box;
   text-align: center;
}
.SelectItem__options::-webkit-scrollbar-track {
   border-radius: 10px;
   margin: 5px 0px;
   background-color: transparent;
}
.SelectItem__options::-webkit-scrollbar {
   width: 3px;
   height: 2px;
   background-color: transparent;
}
.SelectItem__options::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   background-color: #e2e2e2;
}
.SelectItem__item {
   padding: 10px;
   cursor: pointer;
   max-width: 100%;
}
.SelectItem__button__large {
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   padding: 12px 16px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
}
.SelectItem__button__large__placeholder {
   color: #727978 !important;
}
.SelectItem__button__medium {
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   padding: 9px 16px;
   min-height: 44px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
}
.SelectItem__button__medium svg path {
   fill-background: #131f1e;
}
.SelectItem__button__medium__placeholder {
   color: #727978 !important;
}
.SelectItem__button {
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   padding: 8px 8px 8px 12px;
   display: flex;
   align-items: center;
   grid-gap: 8px;
   gap: 8px;
   cursor: pointer;
}
.SelectItem__button .SelectItem__button__icon {
   width: 17.67px;
   height: 17.67px;
}
.SelectItem__button .SelectItem__button__icon path {
   color: #727978;
}
.SelectItem__button__placeholder {
   color: #727978 !important;
}
.SelectItem__active svg:not(.disableIconAnimation) {
   transform: rotate(180deg);
}
.SelectItem__disabled {
   background: #f8fafa;
}
.SelectItem__button__transactions {
   padding: 12px 16px;
   background: #fff;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
   min-width: 223px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.SelectItem__button__transactions__placeholder {
   color: #727978 !important;
}
.divider-align-center{
   text-align: center;
}
.bottom-gap {
    margin-bottom: 23px;
}
.colorInput {
   width: 100%;
}

.colorInput::placeholder {
   color: #8a94a2;
}

.colorInput label {
   width: 100%;
}

.colorInput .colorInput__input {
   border: 1px solid #cddaf1 !important;
   box-sizing: border-box !important;
   border-radius: 4px !important;
   font-family: 'Roboto' !important;
   width: 100% !important;
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   padding: 8px !important;
   background: #FFFFFF;
   min-height: 44px !important;
   color: #131F1E;
   border: 1px solid #E7E9E9 !important;
   border-radius: 12px !important;
}

.colorInput .colorInput__input:focus {
   box-shadow: 0 0 0px 2px #24554E;
   border: 1px solid #ffffff;
   outline: none;
}

.colorInput .colorInput__wrapper .colorInput_label{
   margin-bottom: 10px;
   font-size: 14px;
   font-family: "Roboto";
   font-weight: 600;
   line-height: 1.71;
   font-family: 'AvenirNext-DemiBold';
   color: #3f4f65;
}
.colorInput .colorInput__inputWrapper {
   position: relative;
   width: 100%;
   display: flex;
}
.colorInput .colorInput__inputWrapper .colorInput__icon {
   position: absolute;
   right: 32px;
   transform: translateY(-50%);
   top: 50%;
}

.colorInput .colorInput__inputWrapper .colorInput__picker {
   position: absolute;
   right: 10px;
   transform: translateY(-50%);
   top: 50%;
}

.colorInput__wrapper__top{
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.colorInput__wrapper__example{
   width: 48px;
   border-radius: 8px;
   height: 24px;
   border: 1px solid #E7E9E9;
   box-sizing: border-box;
   cursor: pointer;
}

.colorInput__wrapper{
   display: flex;
   flex-direction: column;
   grid-gap: 12px;
   gap: 12px;
}
.input_new {
   font-family: "Roboto" !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 168% !important;
   font-feature-settings: "tnum" on, "lnum" on !important;
   color: var(--greyscale_90) !important;
   outline: none !important;
   border: none !important;
   background-color: inherit !important;
   width: 100% !important;
   padding: 0px !important;
}

.input_new_date {
   cursor: text;
}

.inputNew {
   min-height: 44px;
}

.inputNew_disabled {
   outline: none;
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
   padding: 12px 16px;
   display: flex;
   align-items: center;
}

.input_header {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.inputSearch .inputNew {
   padding: 6px 16px;
   min-height: 44px;
}

.inputSearch input::placeholder {
   color: #727978;
}

.inputNew__search {
   display: flex;
   width: 100%;
   align-items: center;
}

.inputNew {
   position: relative;
   max-width: 100%;
   background: #fff;
   outline: none;
   border: 1px solid var(--greyscale_10);
   box-sizing: border-box;
   border-radius: 12px;
   padding: 8px 16px;
   display: flex;
   align-items: center;
   height: 100%;
   justify-content: space-between;
}

.inputNew__timepicker {
   border: none !important;
   outline: none !important;
   width: 100%;
   padding: 0px !important;
   box-shadow: none !important;
}

.inputNew__timepicker .ant-picker-input {
   display: flex !important;
}

.inputNew__timepicker .ant-picker-input input {
   width: 100%;
}

.inputNew__timepicker .ant-picker-suffix {
   display: block;
}

.inputNew input {
   background-color: inherit !important;
}

.inputNew .inputNew_icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: auto;
}

.inputNew .inputNew_icon_inactive path {
   fill: #727978;
}

.inputNew svg {
   cursor: pointer;
   margin-right: 8px;
}

.inputNew_search svg {
   margin-left: 8px;
   margin-right: 0px;
}

.inputNew .show-hide {
   cursor: pointer;
   display: flex;
   align-items: flex-end;
}

.inputNew_helper {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 150%;
   color: var(--greyscale_40);
   text-align: right;
   font-feature-settings: "tnum" on, "lnum" on;
}

.inputNew_active {
   background: #fff;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   box-shadow: 0px 0px 4px #54938b;
   border-radius: 12px;
}

.inputNew_label {
   display: inline-block;
   font-family: "Roboto";
   font-style: normal;
   top: -58%;
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   transition: 0.2s ease-in-out;
   font-feature-settings: "tnum" on, "lnum" on;
   color: var(--greyscale_90);
}

.inputNew_label_error {
   color: var(--additional_error);
}

.inputNew_label_disabled {
   color: var(--greyscale_30);
}

.inputNew_label_animation {
   position: absolute;
   color: var(--greyscale_40) !important;
   top: 50%;
   transform: translateY(-50%);
}

.inputNew__calendar {
   position: relative;
}

.inputNew_focused .inputNew_label_animation {
   color: var(--greyscale_90) !important;
   top: -58%;
   transform: translateY(0%);
}

.input_date {
   display: flex;
   flex-direction: column;
   grid-gap: 8px;
   gap: 8px;
   width: 100%;
}

.input {
   width: 100%;
   display: flex;
   grid-gap: 8px;
   gap: 8px;
   flex-direction: column;
}

.input * {
   width: 100%;
}

.input_footer_message {
   margin-bottom: 2px;
   display: flex;
   align-items: center;
}

.input_footer_text {
   margin-left: 6px;
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 150%;
   font-feature-settings: "tnum" on, "lnum" on;
}

.input_footer_text_error {
   color: var(--additional_error);
}

.input_footer_text_success {
   color: var(--additional_success);
}

.input_footer_text_danger {
   color: var(--additional_danger);
}

.focused {
   box-shadow: 0px 0px 4px #54938b;
}

.input_new_disabled {
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
}

.input_buttons {
   text-align: end;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: space-between;
   grid-gap: 2px;
   gap: 2px;
}

.input_number {
   display: flex;
   flex-direction: column;
   grid-gap: 8px;
   gap: 8px;
   width: 100%;
}

.input_number .inputNew {
   padding: 4px 6px 4px 12px;
}

.input_number::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.text_area {
   width: 100%;
}

.text_area textarea {
   scrollbar-color: var(--button-bg-color) #e2e2e2;
   scrollbar-width: 1px;
}

.text_area textarea::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}

.text_area textarea::placeholder {
   color: #727978;
}

.text_area textarea::-webkit-scrollbar {
   width: 5px;
   background-color: transparent;
}

.text_area textarea::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px var(--button-bg-color);
   -webkit-box-shadow: inset 0 0 6px var(--button-bg-color);
   background-color: #e2e2e2;
}

.text_area_new {
   width: 100%;
   background: #fff;
   padding: 12px 16px;
   outline: none;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
   resize: vertical;
}

.text_area_new:focus {
   box-shadow: 0px 0px 4px #54938b;
}

.text_area_new_disabled {
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
}

.input_number_button {
   cursor: pointer;
   background: #f0f2f2;
   border-radius: 4px;
   border: none;
   display: flex;
   align-items: center;
   padding: 5px 3px;
   justify-content: center;
   transition: 0.1s ease;
}

.input_number_button:active {
   background: #24554e;
}

.input_number_button:active svg path {
   fill: #fff;
}

.input_number_button_disabled {
   border-radius: 4px;
   border: none;
   display: flex;
   align-items: center;
   padding: 6px 4px;
   justify-content: center;
   transition: 0.1s ease;
   cursor: auto;
   background: inherit;
}

.input_number_button_disabled svg {
   margin: auto auto;
}

.input_number_button svg {
   margin: auto auto;
}

.inputNew_disabled {
   background: #f8fafa;
}

.calendar__wrapper {
   top: 100%;
   right: 0;
   position: absolute;
   z-index: 9999;
}

.inputNew__calendar__icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 32px;
   height: 32px;
}

.inputNew__calendar__icon svg {
   margin: 0px;
}

.inputNew__calendar__icon path {
   fill: var(--greyscale_40);
}

.inputNew__calendar__icon__selected path {
   fill: var(--greyscale_90);
}

.inputNew__calendar__icon__active {
   background-color: var(--primary_80);
   border-radius: 8px;
   width: 32px;
   height: 32px;
}

.inputNew__calendar__icon__active path {
   fill: var(--greyscale_0);
}

.input__default__label {
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   font-feature-settings: "tnum" on, "lnum" on;
   color: #131f1e;
   text-align: start;
}

.input_new_date {
   font-size: 12px;
}

.ant-picker-now a {
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   font-feature-settings: "tnum" on, "lnum" on;
   color: #131f1e;
   text-align: start;
}

.ant-picker-now a:hover {
   color: #131f1e !important;
}

.ant-picker-ok {
   height: 100%;
   display: flex !important;
   align-items: center;
}

.ant-picker-ok button {
   font-family: "Roboto";
   font-weight: 400;
   font-size: 14px;
   border-radius: 12px;
   border: none;
   letter-spacing: 1%;
   text-align: center;
   width: auto;
   cursor: pointer;
   -webkit-user-select: none;
           user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   background-color: var(--primary_80);
   border: 1px solid;
   transition: 0.3s ease;
   border: none !important;
   color: var(--greyscale_0);
}

.ant-picker-ok button:hover {
   background-color: var(--blackscale_70);
}

.ant-picker-ok button:active {
   background-color: var(--blackscale_80);
}

.ant-picker-ok button_disabled {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}

.ant-picker-ok button_disabled:hover {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}

.ant-picker-ok button_disabled:active {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}

.ant-picker-ok button_disabled svg path {
   fill: var(--blackscale_40);
}

.ant-picker-ranges {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
   background: #e8f2f1 !important;
}

.ant-picker-time-panel-column:hover {
   scrollbar-color: var(--button-bg-color) #e2e2e2;
   scrollbar-width: 1px;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar {
   width: 5px;
   background-color: transparent;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px var(--button-bg-color);
   -webkit-box-shadow: inset 0 0 6px var(--button-bg-color);
   background-color: #e2e2e2;
}

.inputNew__copy__icon {
   display: flex;
   align-items: center;
   max-width: -webkit-max-content;
   max-width: max-content;
}

.inputNew__copy__icon path {
   fill: #727978;
}

.inputNew__icon {
   display: flex;
   align-items: center;
   max-width: -webkit-max-content;
   max-width: max-content;
}

.inputNew__icon svg {
   margin-right: -1px;
}


.inputLeftText {
   display: flex;
   grid-gap: 1px;
   gap: 1px;
   align-items: center;
}

.inputLeftText__content {
   width: auto;
   font-size: 12px;
}
.disabled_text {
    width: 100%;
    min-height: 40px;
    color: grey;
    border: 1px solid #e7e9e9;
    border-radius: 12px;
    padding: 9px 16px;
}
.fontSelector {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
}

.fontSelector__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;
    gap: 8px;
    padding: 8px 8px 8px 12px;
    background-color: #ffffff;
    border: 1px solid #e7e9e9;
    border-radius: 12px;
    cursor: pointer;
}

.fontSelector__button.active .fontSelector__button__icon {
    transform: rotate(180deg);
}

.fontOptions {
    overflow-y: auto;
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 9999;
    top: 100%;
    border-radius: 12px;
    z-index: 1000;
    border: 1px solid #e7e9e9;
    max-height: 300px;
    scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
    scrollbar-width: thin;
    box-sizing: border-box;
    text-align: center;
}

.fontOptions::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 5px 0px;
    background-color: transparent;
}

.fontOptions::-webkit-scrollbar {
    width: 3px;
    height: 2px;
    background-color: transparent;
}

.fontOptions::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: #e2e2e2;
}

.fontOptions__item {
   padding: 10px;
   cursor: pointer;
   max-width: 100%;
}


.add-section-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: transparent;
    border-top: none;
    padding: 0px;
}

.add-section-left-side {
    display: -webkit-flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-section-left-side-icon{
    border-radius: 8px;
    display: flex;

}

.add-section-left-side:hover {
    cursor: pointer;
    background-color: inherit;
}
.add-section-name-and-description {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    color: #4f5863;
}
.add-section-name {
    font-size:16px;
    font-weight: bold;
    padding-bottom:10px;
}
.add-section-name-and-description {
    font-size: 12px;
}

.SectionComponents__addSection{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.add-section-group{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.add-section-group-top{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.add-section-group-button{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    cursor: pointer;
    transition: 0.2s all;
}

.add-section-group-item-new{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    transition: outline 0.3s;
}

.hoverToAdd{
    height: 32px;
    width: 100%;
    background-color: #F8FAFA;
    border: 1px solid #E7E9E9;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    font-family: 'Roboto';
}

.hoverToAdd span{
    color: #727978;
    font-size: 12px;
    font-weight: bold;
}

.add-section-left-side-icon{
    width: 100%;
}

.add-section-left-side-icon svg{
    width: 100%;
}

.add-section-group-item-new:hover{
    outline: 1px solid #24554E;
}

.add-section-left-side-icon:hover .add-column-section {
    display: block; 
}

.add-section-left-side-icon .add-column-section{
    position: absolute;
    width: 91px;
    height: 36px;
    background-color: #24554E;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: white;
    font-weight: bold;
    line-height: 2px;
    display: none;
    cursor: pointer;
    margin: auto;
    inset: 0;
    bottom: 32px !important;
}


.add-section-items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: transparent;
    border-top: none;
    padding: 0px;
}

.add-section-left-side {
    display: -webkit-flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-section-left-side-icon{
    border-radius: 8px;
    display: flex;

}

.add-section-items:hover {
    cursor: pointer;
    background-color: inherit;
}
.add-section-name-and-description {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    color: #4f5863;
}
.add-section-name {
    font-size:16px;
    font-weight: bold;
    padding-bottom:10px;
}
.add-section-name-and-description {
    font-size: 12px;
}

.SectionComponents__addSection{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.add-section-group{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.add-section-group-top{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.add-section-group-button{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    cursor: pointer;
    transition: 0.2s all;
}

.add-section-group-item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
    gap: 8px;
}

.add-section-left-side-icon{
    width: 100%;
}

.add-section-left-side-icon svg{
    width: 100%;
}

.addColumnSection{
    height: 93px;
    border: 1px solid #E7E9E9;
    background-color: #F8FAFA;
    border-radius: 8px;
}

.add-column-section-items .add-column-section{
    position: absolute;
    width: 91px;
    height: 36px;
    background-color: #24554E;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: white;
    font-weight: bold;
    line-height: 2px;
    display: none;
    cursor: pointer;
    margin: auto;
    inset: 0;
}

.add-column-section-items{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: transparent;
    border-top: none;
    width: 100%;
    padding: 0.5rem;
    position: relative;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    width: 89%;
}

.add-column-section-items:hover{
    background: #E8F2F1;
    border-radius: 8px;
}

.add-column-section-items:hover .add-column-section {
    display: block; 
}

.add-column-group{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    grid-gap: 12px;
    gap: 12px;
    align-items: center;
}

.info {
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   padding: 12px;
   display: flex;
   border-radius: 12px;
   justify-content: space-between;
}
.info .info-left {
   display: flex;
   align-items: flex-start;
   grid-gap: 12px;
   gap: 12px;
}
.info .info-left div {
   display: flex;
}
.info div:nth-child(2) svg {
   cursor: pointer;
}

.ImageEditable {
    margin-top: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ImageEditable>p {
    font-size: 14px;
    font-weight: bold;
}

.ImageEditable>img {
    width: 93%;
    margin-bottom: 4px;
}

.ImageEditable>input {
    display: none;
}

.ImageEditable>.button {
    width: 93%;
}

.ImageEditable>.upload_favicon_button,
.ImageEditable>.upload_favicon_button:hover,
.ImageEditable>.upload_favicon_button:focus,
.ImageEditable>.upload_favicon_button:active {
    border: 2px solid #006dff;
    background-color: #fff;
    color: #006dff;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto";
}

.ImageEditable>.upload_favicon_button-revert,
.ImageEditable>.upload_favicon_button-revert:hover,
.ImageEditable>.upload_favicon_button-revert:focus,
.ImageEditable>.upload_favicon_button-revert:active {
    border: 2px solid #24554e;
    background-color: #fff;
    color: #24554e;
    margin-top: 5px;
}

.ImageEditable {
    margin-top: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sideBar__settings__top input{
    display: none;
}
.sideBar__settings__top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 12px;
    gap: 12px;
}
.sideBar__settings__top button{
    width: 100%;
}

.sideBar__settings__top img{
    width: 100% !important;
    border-radius: 12px;
}

.sideBar__settings__typograpy{
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}


.sideBar__settings__seo{
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}

.sideBar__settings__seo textarea{
    min-height: 122px;
}

.sideBar__settings{
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}

.sideBar__settings__info div{
    display: flex;
    align-items: center;
}
.sideBar__settings__info{
    display: flex;
    background: #F8FAFA;
    align-items: flex-start;
    border: 1px solid #E7E9E9;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 12px;
    grid-gap: 8px;
    gap: 8px;
}

.sideBar__settings__info__right{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    grid-gap: 8px;
    gap: 8px;
}
body p,
span {
    line-height: initial;
}

.sidebarContainer * {
    font-family: "AvenirNext", "Roboto", serif;
}

.sidebarContainer {
    background-color: #f0f4f7 !important;
}

.ImageEditable {
    width: 100% !important;
}

.ImageEditable>div,
.ImageEditable>button,
.ImageEditable>input,
.ImageEditable>img {
    width: 100% !important;
}

#right_container {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    transition: none;
}

.Editor .sidebarContainer {
    overflow-x: hidden;
    padding: 16px;
}

.Editor .toggleSidebar {
    position: absolute;
    top: 50vh;
    left: 8px;
    z-index: 999999;
    padding: 10px 2px;
    background: #fff;

}

.add-section {
    width: 100%;
    color: #24554e !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 2px dashed #24554e;
    border-radius: 4px;
    padding: 11px 16px;
}

.add-section:hover {
    background-color: rgba(124, 183, 64, 0.1) !important;
    cursor: pointer;
}

.add-section:focus,
.add-new-section:focus,
.add-new-section_button:focus {
    outline: none !important;
}

.add-new-section_button {
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;
    color: #24554e;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-section_button img {
    margin-right: 10px;
}

.topbar {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #2d6dff;
    transition: 0.5s all ease;
    overflow: hidden;
}

.right_container_wrapper {
    transition: flex 0.7s ease all;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right_container_style {
    background-color: #f0f4f7;
}

.phoneMode {
    transition: all 0.65s ease 0s !important;
    width: 375px;
    height: 667px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.phoneMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

#preview_button {
    width: 156px !important;
    padding: 7px 0 !important;
    border-radius: 40px !important;
    background-color: transparent !important;
    border: 2px solid white;
    text-align: center !important;
    color: #fff !important;
}

.centered_mainMenu_save_button {
    margin-left: 12px;
    border-radius: 4px;
    border: 2px solid #006dff;
    width: 130px;
    height: 36px;
}

#save_button {
    width: 100% !important;
    height: 100%;
    background-color: #f0f4f7;
    text-align: center !important;
    color: #006dff !important;
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto";
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.43;
}

.tabletMode {
    transition: all 0.65s ease 0s !important;
    width: 600px;
    height: 720px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.tabletMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.centered_mainMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.topbar_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.topbar_item {
    display: flex;
    margin: 0 4px !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 3.6px;
    background-color: #fff;
}

.topbar_item img {
    margin: 0 5px;
    height: 16px;
}

.topbar_item span {
    color: #ffffff;
    font-size: 16px;
}

.mainMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #2d6dff; */
    transition: 0.5s all ease;
    /* height: 70px; */
    overflow: hidden;
    /* display: none; */
}

.bottomBar {
    padding: 16px 0;
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    right: 32px;
    z-index: 100;
}

.bottombar-items {
    width: calc(100% - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottombar-items-centered {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bottombar-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottombar-item-link:hover .bottombar-item-page {
    color: #ffffff;
    border: 2px solid #ffffff
}

.bottombar-item-link:hover .bottombar-item-text {
    color: #ffffff;
}

.bottombar-item-link {
    display: flex;
    align-items: center
}

.bottombar-item-page {
    height: 40px;
    width: 40px;
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Roboto-Medium', sans-serif;
}

.bottombar-item-text {
    font-size: 14px;
    color: #a2a2a2;
    font-family: 'Roboto-Medium', sans-serif;
}


.save_container {
    width: 350px;
    height: -webkit-max-content;
    height: max-content;
    background: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.settings_icon {
    margin-left: 140px !important;
}

.page-preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-icon {
    margin: 0 10px;
}

.ui.dividing.header {
    border-radius: 0 !important;
    margin-bottom: 1% !important;
}

.SectionComponent label {
    color: #3f4f65 !important;
    font-weight: 600;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #008055 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #16a977 !important;
}

.SectionComponents .component_name {
    color: #59636f !important;
    font-weight: 800;
    font-size: 16px;
    margin-left: 0 !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2d91fb !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    border-radius: 0 !important;
}

.ui.form .field>.selection.dropdown {
    border-radius: 0 !important;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    border-radius: 0 !important;
}

body .ui.dividing.header {
    margin-bottom: 15px !important;
}

.menu-item-right .ui.dropdown>.text {
    display: none;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar_top_section_text,
.settings_icon {
    border: none !important;
    background: #f0f4f7;
    color: #fff;
}

.settings_icon {
    position: absolute !important;
    right: 16px !important;
}

.Tabs {
    background-color: #f0f4f7;
}

.Tabs .ui.attached.tabular.menu {
    background-color: #fff !important;
    border-radius: 4px;
    margin-bottom: 8px;
}

/* .Tabs .ui.attached.tabular.menu {
    background-color: #fbfdff;
} */

.Tabs .ui.attached.tabular.menu .item {
    color: #3f4f65 !important;
    width: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
}

.Tabs .ui.tabular.menu .active.item {
    font-weight: 500;
}

.Tabs .ui.attached.tabular.menu .active.item {
    background-color: #24554e !important;
    color: #fff !important;
    border-radius: 0 !important;
}

.Tabs .ui.attached.segment {
    border: none !important;
    background-color: #f0f4f7 !important;
}

body p,
span {
    line-height: initial;
    word-wrap: break-word;
    word-break: break-word;
}

.sidebarContainer {
    background-color: #ffffff !important;
    max-width: 308px !important;
}

.ImageEditable {
    width: 100% !important;
}

.ImageEditable>div,
.ImageEditable>button,
.ImageEditable>input,
.ImageEditable>img {
    width: 100% !important;
}

#right_container {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    transition: none;
}

.EditorDrag .sidebarContainer {
    overflow-x: hidden;
    border-right: 1px solid #E7E9E9;
    padding: 16px;
}

.EditorDrag .toggleSidebar {
    position: fixed;
    top: 3.5vh;
    background-color: white;
    z-index: 99999999;
    background: #FFFFFF;
    border: 1px solid #E7E9E9;
    border-radius: 0px 16px 16px 0px;
    cursor: pointer;
    width: 25px;
    height: 36px;
    display: flex;
    align-items: center;
}


.EditorDrag .toggleSidebar::after {
    position: fixed;
    content: '';
    top: 3.5vh;
    background-color: #fff;
    width: 2px;
    height: 36px;
    margin-left: -1px;
}


.add-section {
    width: 100%;
    color: #24554e !important;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    border: 2px dashed #24554e;
    border-radius: 4px;
    padding: 11px 16px;
}

.add-section:hover {
    background-color: rgba(124, 183, 64, 0.1) !important;
    cursor: pointer;
}

.add-section:focus,
.add-new-section:focus,
.add-new-section_button:focus {
    outline: none !important;
}

.add-new-section_button {
    background: transparent;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 100%;
    color: #24554e;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-section_button img {
    margin-right: 10px;
}

.topbar {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #2d6dff;
    transition: 0.5s all ease;
    overflow: hidden;
}

.right_container_wrapper {
    transition: flex 0.7s ease all;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right_container_style {
    background: #f4f7f7;
}

.phoneMode {
    transition: all 0.65s ease 0s !important;
    width: 375px;
    height: 667px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.phoneMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

#preview_button {
    width: 156px !important;
    padding: 7px 0 !important;
    border-radius: 40px !important;
    background-color: transparent !important;
    border: 2px solid white;
    text-align: center !important;
    color: #fff !important;
}

.centered_mainMenu_save_button {
    margin-left: 12px;
    border-radius: 4px;
    border: 2px solid #006dff;
    width: 130px;
    height: 36px;
}

#save_button {
    width: 100% !important;
    height: 100%;
    background-color: #f0f4f7;
    text-align: center !important;
    color: #006dff !important;
    font-size: 16px;
    font-weight: 600;
    font-family: 'AvenirNext-DemiBold';
    cursor: pointer;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.43;
}

#save_button_exit {
    width: 100% !important;
    height: 100%;
    background-color: #f0f4f7;
    text-align: center !important;
    color: #006dff !important;
    font-size: 16px;
    font-weight: 600;
    font-family: 'AvenirNext-DemiBold';
    cursor: pointer;
    padding: 0 !important;
    line-height: 1.43;
}

.tabletMode {
    transition: all 0.65s ease 0s !important;
    width: 600px;
    height: 720px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.tabletMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.centered_mainMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.topbar_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.topbar_item {
    display: flex;
    margin: 0 4px !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 3.6px;
    background-color: #fff;
}

.topbar_item img {
    margin: 0 5px;
    height: 16px;
}

.topbar_item span {
    color: #ffffff;
    font-size: 16px;
}

.mainMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #2d6dff; */
    transition: 0.5s all ease;
    /* height: 70px; */
    overflow: hidden;
    /* display: none; */
}

.bottomBar {
    padding: 16px 0;
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    right: 32px;
    z-index: 100;
}

.bottombar-items {
    width: calc(100% - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottombar-items-centered {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bottombar-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottombar-item-link:hover .bottombar-item-page {
    color: #ffffff;
    border: 2px solid #ffffff
}

.bottombar-item-link:hover .bottombar-item-text {
    color: #ffffff;
}

.bottombar-item-link {
    display: flex;
    align-items: center
}

.bottombar-item-page {
    height: 40px;
    width: 40px;
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Roboto-Medium', sans-serif;
}

.bottombar-item-text {
    font-size: 14px;
    color: #a2a2a2;
    font-family: 'Roboto-Medium', sans-serif;
}


.save_container {
    width: 350px;
    height: -webkit-max-content;
    height: max-content;
    background: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.settings_icon {
    margin-left: 140px !important;
}

.page-preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-icon {
    margin: 0 10px;
}

.ui.dividing.header {
    border-radius: 0 !important;
    margin-bottom: 1% !important;
}

.SectionComponent {
    background-color: #FFFFFF !important;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #008055 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #16a977 !important;
}

.EditorDrag .SectionComponents .component_name {
    color: #59636f !important;
    font-family: 'AvenirNext-DemiBold';
    font-size: 13px;
    margin-left: 0 !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2d91fb !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    border-radius: 0 !important;
}

.ui.form .field>.selection.dropdown {
    border-radius: 0 !important;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    border-radius: 0 !important;
}

body .ui.dividing.header {
    margin: 5px !important;
    border-bottom: none;
}

.EditorDrag .menu-item-right .ui.dropdown>.text {
    display: none;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EditorDrag .sidebar_top_section_text,
.settings_icon {
    border: none !important;
    background: #f0f4f7;
    color: #fff;
}

.EditorDrag .settings_icon {
    position: absolute !important;
    right: 16px !important;
}

.EditorDrag .Tabs {
    background-color: #fff;
}

.EditorDrag .Tabs .ui.attached.tabular.menu {
    background-color: #F0F2F2 !important;
    border-radius: 12px;
    margin-bottom: 32px;
    border-bottom: none !important;
}

.EditorDrag .Tabs .ui.attached.tabular.menu .item {
    color: #131F1E !important;
    width: 33.3%;
    padding: 0;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 168%;
}

.EditorDrag .Tabs .ui.attached.tabular.menu .active.item {
    background-color: #24554E !important;
    color: #fff !important;
    border-radius: 12px !important;
}

.EditorDrag .Tabs .ui.attached.segment {
    border: none !important;
    background-color: #fff !important;
}


.EditorDrag .ui.vertical.SectionComponents.menu {
    box-shadow: none;
    border: none;
}

.elements__helper {
    position: relative;
    margin-top: 12px;
    display: flex;
    justify-content: center;
}

.elements__helper__icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.ui.uncover.sidebar {
    z-index: auto;
    transform: none !important;
    will-change: inherit;
}

.elements_content {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.elements_content .elementName {
    font-size: 14px;
    font-family: 'AvenirNext-Medium';
    padding: 4px 0;
    font-weight: 500;
    color: #3f4f65;
}

.element_content {
    background: #FFFFFF;
    border: 1px solid #E7E9E9;
    border-radius: 8px;
    padding: 0px;
    text-align: center;
    height: 76px;
    width: 122px;
    transition: 0.3s all;
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.element_content_small {
    background: #fbfdff;
    padding: 0px;
    text-align: center;
    height: 70px;
    width: 70px;
    margin: 4px 0;
    border: 1px solid #cddaf1;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .element_content:hover .elementIcon svg path:last-child {
    fill: #24554E;
} */

.element_content:hover {
    border: 1px solid #24554E;
    box-shadow: 0 0 0px 1px #24554E;
}

/* .EditorDrag .ui.form .field .ui.input input,
.EditorDrag .ui.form .field>.selection.dropdown,
.EditorDrag .ui.form .fields .field .ui.input input,
.EditorDrag .ui.form input:not([type]), .ui.form input[type=date],
.EditorDrag .ui.form input[type=datetime-local],
.EditorDrag .ui.form input[type=email],
.EditorDrag .ui.form input[type=file],
.EditorDrag .ui.form input[type=number],
.EditorDrag .ui.form input[type=password],
.EditorDrag .ui.form input[type=search],
.EditorDrag .ui.form input[type=tel],
.EditorDrag .ui.form input[type=text],
.EditorDrag.ui.form input[type=time],
.EditorDrag .ui.form input[type=url],
.EditorDrag .ui.selection.dropdown {
    font-family: 'AvenirNext-DemiBold';
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.4px;
    background: #fbfdff;
    border-radius: 4px !important;
    border: 1px solid #cddaf1;
    padding: 5px 10px;
    font-size: 13px;
    color: #3f4f65;
} */


.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer,
.EditorDrag #right_container {
    scrollbar-color: rgba(119, 119, 119, 0.3) rgb(226, 226, 226);
    scrollbar-width: thin;
}

.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer::-webkit-scrollbar-track,
.EditorDrag #right_container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer::-webkit-scrollbar,
.EditorDrag #right_container::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.EditorDrag .ui.uncover.left.wide.visible.sidebar.sidebarContainer::-webkit-scrollbar-thumb,
.EditorDrag #right_container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: rgb(226, 226, 226);
}


.menu-item-right {
    display: flex;
}

.addSectionPopup {
    display: flex;
    width: 268px;
    padding: var(--spacing-xs, 8px);
    flex-direction: column;
    align-items: flex-start;
    grid-gap: var(--spacing-xs, 8px);
    gap: var(--spacing-xs, 8px);
    border-radius: 12px;
    background: var(--grey-scale-0, #FFF);
    box-shadow: 1px 2px 2px 0px rgba(19, 31, 30, 0.04), 0px 4px 10px 0px rgba(19, 31, 30, 0.10);
}

.columnCanvas,
.smartBlocks {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    grid-gap: 1.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    background-color: #F8FAFA;
    border-radius: 10px;
}

.columnCanvas:hover,
.smartBlocks:hover {
    cursor: pointer;
    background-color: #E8F2F1;
}

.popupSection {
    display: flex;
    flex-direction: column;
    grid-gap: 0.3rem;
    gap: 0.3rem;
}

.smartBlocks strong,
.columnCanvas strong {
    font-size: 14px;
}

.smartBlocks span,
.columnCanvas span {
    font-size: 12px;
    color: #3f4f65;
}

body p,
span {
    line-height: inherit;
}

.sidebarContainer * {
    font-family: "Roboto", serif;
}

.ImageEditable {
    width: 100% !important;
}

.ImageEditable>div,
.ImageEditable>button,
.ImageEditable>input,
.ImageEditable>img {
    width: 100% !important;
}

#right_container {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    transition: none;
}

.Editor .sidebarContainer {
    background-color: #f7f8f9;
    overflow-x: hidden;
}

.Editor .toggleSidebar {
    position: absolute;
    top: 50vh;
    z-index: 999999;
    padding: 10px 2px;
    background: #fff;

}

.add-section {
    width: 100%;
    color: #2e91fc;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
}

.add-section:hover {
    background: #b0c5df;
    color: #265d97;

}

.add-new-section {
    justify-content: center !important;
}

.topbar {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #233a57;
    transition: 0.5s all ease;
    overflow: hidden;
}

.right_container_wrapper {
    transition: flex 0.7s ease all;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.phoneMode {
    transition: all 0.65s ease 0s !important;
    width: 382px;
    height: 667px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.phoneMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.tabletMode {
    transition: all 0.65s ease 0s !important;
    width: 600px;
    height: 720px;
    border: 1px solid #e5e9ed !important;
    overflow: hidden;
}

.tabletMode iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.centered_topbar {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar_items {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.topbar_item {
    display: flex;
    margin: 0 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.topbar_item img {
    margin: 0 5px;
}

.topbar_item span {
    color: #ffffff;
    font-size: 16px;
}

.bottombar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #233a57;
    transition: 0.5s all ease;
    height: 70px;
    overflow: hidden;
}

.bottombar-items {
    width: calc(100% - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottombar-items-centered {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bottombar-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottombar-item-link:hover .bottombar-item-page {
    color: #ffffff;
    border: 2px solid #ffffff
}

.bottombar-item-link:hover .bottombar-item-text {
    color: #ffffff;
}

.bottombar-item-link {
    display: flex;
    align-items: center
}

.bottombar-item-page {
    height: 40px;
    width: 40px;
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: 'Roboto-Medium', sans-serif;
}

.bottombar-item-text {
    font-size: 14px;
    color: #a2a2a2;
    font-family: 'Roboto-Medium', sans-serif;
}


.save_container {
    width: 350px;
    height: -webkit-max-content;
    height: max-content;
    background: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.settings_icon {
    margin-left: 140px !important;
}

.page-preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-icon {
    margin: 0 10px;
}

.ui.dividing.header {
    border-radius: 0 !important;
    margin-bottom: 1% !important;
}

.SectionComponent label {
    color: #3f4f65 !important;
    font-weight: 600;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after {
    background-color: #008055 !important;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
    color: #16a977 !important;
}

.SectionComponents .component_name {
    color: #59636f !important;
    font-weight: 800;
    font-size: 16px;
    margin-left: 15px;
}

.ui.tabular.menu .item {
    color: #3d2828de !important;
}

.ui.tabular.menu .active.item {
    color: #3d2828de !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #2d91fb !important;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    border-radius: 0 !important;
}

.ui.form .field>.selection.dropdown {
    border-radius: 0 !important;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    border-radius: 0 !important;
}

.ui.selection.dropdown {
    border-radius: 0 !important;
}

body .ui.dividing.header {
    margin-bottom: 15px !important;
}

.menu-item-right .ui.dropdown>.text {
    display: none;
}

#loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading__SpinnerContainer {
    width: 100%;
    display: flex;
    height: 100%;
    /* padding-top: 10px; */
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 30;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #24554e transparent #24554e transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

