.colorInput {
   width: 100%;
}

.colorInput::placeholder {
   color: #8a94a2;
}

.colorInput label {
   width: 100%;
}

.colorInput .colorInput__input {
   border: 1px solid #cddaf1 !important;
   box-sizing: border-box !important;
   border-radius: 4px !important;
   font-family: 'Roboto' !important;
   width: 100% !important;
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   padding: 8px !important;
   background: #FFFFFF;
   min-height: 44px !important;
   color: #131F1E;
   border: 1px solid #E7E9E9 !important;
   border-radius: 12px !important;
}

.colorInput .colorInput__input:focus {
   box-shadow: 0 0 0px 2px #24554E;
   border: 1px solid #ffffff;
   outline: none;
}

.colorInput .colorInput__wrapper .colorInput_label{
   margin-bottom: 10px;
   font-size: 14px;
   font-family: "Roboto";
   font-weight: 600;
   line-height: 1.71;
   font-family: 'AvenirNext-DemiBold';
   color: #3f4f65;
}
.colorInput .colorInput__inputWrapper {
   position: relative;
   width: 100%;
   display: flex;
}
.colorInput .colorInput__inputWrapper .colorInput__icon {
   position: absolute;
   right: 32px;
   transform: translateY(-50%);
   top: 50%;
}

.colorInput .colorInput__inputWrapper .colorInput__picker {
   position: absolute;
   right: 10px;
   transform: translateY(-50%);
   top: 50%;
}

.colorInput__wrapper__top{
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}

.colorInput__wrapper__example{
   width: 48px;
   border-radius: 8px;
   height: 24px;
   border: 1px solid #E7E9E9;
   box-sizing: border-box;
   cursor: pointer;
}

.colorInput__wrapper{
   display: flex;
   flex-direction: column;
   gap: 12px;
}