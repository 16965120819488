.text_column_width_new {
    border: 1px solid #e7e9e9 !important;
    padding: 5px;
    width: min-content !important;
    max-width: 67px;
    height: 24px;
    border-radius: 8px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 168%;
    color: #131f1e;
}

.text_column_content {
    display: flex;
    align-items: center;
    margin-top: 8px;
 }

.text_column_content input{
    background: #e7e9e9;
    background-image: linear-gradient(#7baea9,#7baea9);
    border-radius: 2px;
    height: 2px;
}

.text_column_content input::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
  }
  .text_column_content input::-ms-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
  } 

  .text_column_content input::-moz-range-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
  }
 .text_column_name {
    margin-top: 16px;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
    color: #3f4f65;
    font-family: 'AvenirNext-DemiBold';
 }

 .text_column_top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
 }input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background:  #E7E9E9;; /* Otherwise white in Chrome */
    background-image: linear-gradient( #7BAEA9,#7BAEA9);
    border-radius: 2px;
    height: 2px;
    background-repeat: no-repeat;
    background-size: 0% 100%;
  }
 
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
 
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
 
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
 
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
 
 
  /* Special styling for WebKit/Blink */
 input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
 
  }
 
  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
 
  }
 
  /* All the same stuff for IE */
  input[type=range]::-ms-thumb {
    cursor: pointer;
    background: #24554E;
     width: 20px;
     height: 20px;
     border-radius: 50%;
 
  }

  .text_column_content{
    margin-bottom: 12px;
  }