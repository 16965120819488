.ImageEditable {
    margin-top: 20px;
    width: 300px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}
.sideBar__settings__top input{
    display: none;
}
.sideBar__settings__top{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.sideBar__settings__top button{
    width: 100%;
}

.sideBar__settings__top img{
    width: 100% !important;
    border-radius: 12px;
}

.sideBar__settings__typograpy{
    display: flex;
    flex-direction: column;
    gap: 24px;
}


.sideBar__settings__seo{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.sideBar__settings__seo textarea{
    min-height: 122px;
}

.sideBar__settings{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.sideBar__settings__info div{
    display: flex;
    align-items: center;
}
.sideBar__settings__info{
    display: flex;
    background: #F8FAFA;
    align-items: flex-start;
    border: 1px solid #E7E9E9;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 12px;
    gap: 8px;
}

.sideBar__settings__info__right{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 8px;
}