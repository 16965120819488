button {
    border: 0;
}

@media all and (min-width: 320px) and (max-width: 375px) {
    .landing_1_header_buttons,
    .landing_2_header_buttons,
    .landing_3_header_buttons {
        font-size: 8px!important;
        padding: 0 5px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 0 8px!important;
        font-size: 10px!important;
        line-height: 1.4;
    }
    .landing_1_section_5_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_2_section_2_content_button,
    .landing_6_section_5_content_button,
    .landing_7_section_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 10px 20px!important;
        font-size: 12px!important;
    }
    .landing_3_section_5_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_4_header_buttons,
    .landing_5_header_buttons {
        font-size: 10px!important;
        padding: 0 10px !important;
    }
    .landing_4_section_2_content_button,
    .landing_4_section_6_content_button {
        padding: 10px 20px!important;
        font-size: 14px!important;
    }

    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button,
    .landing_6_header_button {
        padding: 7px 15px!important;
        font-size: 12px!important;
    }
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 10px!important;
        padding: 5px 10px !important;
    }
}

@media all and (min-width: 376px) and (max-width: 417px) {
    .landing_1_header_buttons,
    .landing_2_header_buttons,
    .landing_3_header_buttons,
    .landing_5_header_buttons {
        font-size: 9px!important;
        padding: 0 6px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 0 12px!important;
        font-size: 10px!important;
        line-height: 1.4;
    }
    .landing_1_section_5_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_2_section_2_content_button,
    .landing_6_section_5_content_button,
    .landing_7_section_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 10px 20px!important;
        font-size: 12px!important;
    }
    .landing_3_section_5_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_4_header_buttons {
        font-size: 11px!important;
        padding: 0 15px !important;
    }
    .landing_4_section_2_content_button,
    .landing_4_section_6_content_button,
    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button,
    .landing_6_header_button {
        padding: 10px 20px!important;
        font-size: 14px!important;
    }
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 10px!important;
        padding: 5px 15px !important;
    }
}

@media all and (min-width: 418px) and (max-width: 572px) {
    .landing_1_header_buttons {
        font-size: 10px!important;
        padding: 5px 9px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 0 16px!important;
        font-size: 10px!important;
        line-height: 1.4;
    }
    .landing_2_header_buttons,
    .landing_3_header_buttons,
    .landing_5_header_buttons,
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 10px!important;
        padding: 5px 8px !important;
    }
    .landing_4_header_buttons {
        font-size: 12px!important;
        padding: 5px 20px !important;
    }
    .landing_2_section_2_content_button,
    .landing_6_section_5_content_button,
    .landing_7_section_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 10px 20px!important;
        font-size: 14px!important;
    }
    .landing_3_section_5_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button {
        padding: 10px 20px!important;
        font-size: 16px!important;
    }
    .landing_4_section_2_content_button,
    .landing_4_section_6_content_button,
    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button {
        padding: 15px 30px!important;
        font-size: 16px!important;
    }
}

@media all and (min-width: 573px) and (max-width: 768px) {
    .landing_1_header_buttons,
    .landing_2_header_buttons,
    .landing_3_header_buttons,
    .landing_7_header_buttons,
    .landing_8_header_buttons,
    .landing_9_header_buttons {
        font-size: 12px!important;
        padding: 5px 10px !important;
    }
    .landing_1_section_1_content_premium_member_button {
        padding: 2px 25px!important;
        font-size: 12px!important;
        line-height: 1.4;
    }
    .landing_3_section_5_content_button,
    .landing_5_section_1_content_button,
    .landing_5_section_4_content_button,
    .landing_5_section_pricing_content_button,
    .landing_6_section_2_content_button,
    .landing_6_section_pricing_content_button,
    .landing_8_section_content_button,
    .landing_9_section_content_button,
    .landing_10_section_content_button,
    .landing_12_section_content_button,
    .hero_section_content_button {
        padding: 15px 30px!important;
        font-size: 16px!important;
    }
    .landing_4_header_buttons {
        font-size: 14px!important;
        padding: 5px 35px !important;
    }
    .landing_5_header_buttons {
        font-size: 14px!important;
        padding: 5px 25px !important; 
    }
}

@media all and (min-width: 769px) and (max-width: 910px) {
    .landing_9_header_buttons {
        font-size: 20px!important;
        padding: 10px 15px !important;
    }
}
