.SectionComponents {
    width: 100% !important;
}

.SectionComponents__top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
}

.SectionComponents__top__left {
    display: flex;
    gap: 24px;
    align-items: center;
}

.SectionComponents__top__icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.SectionComponents.menu {
    padding-bottom: 16px;
}

.Section-Component {}

.SectionComponents.menu .item {
    padding: 8px 0;
}

.SectionComponents.menu .item:before {
    display: none;
}

.SectionComponents.menu .SectionComponents_name {
    padding: 10px 12px;
    border: 1px solid #dfe5eb;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Avenir Next";

}

.SectionComponents.menu .SectionComponents_name .component_icon_up {
    display: none;
}

.SectionComponents.menu .SectionComponents_name .component_name {
    color: #3f4f65;
}

.SectionComponents.menu .item img {
    width: 16px !important;
}

.SectionComponents.menu .item .header {
    padding: 10px !important;
}

.SectionComponent img {
    border: none !important;
}

.SectionComponents .SectionComponent {
    /* background-color:#f7f7f7; */
    padding: 16px;
}

.SectionComponent {
    -webkit-transition: 1s all;
    -o-transition: 1s all;
    transition: 1s all;
}

#section_remove_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

/* .sidebarContainer .mce-notification-inner {
    display:none!important;
}

body .mce-widget.mce-notification.mce-notification-warning.mce-has-close.mce-in {
    display: none!important;
} */

.maincomponent {
    margin: 5px;
    padding-bottom: 16px;
}

.SectionComponents.menu .SectionComponents_name {
    display: flex;
    align-items: flex-start;
    padding: 8px 12px;
    border: none !important;
    gap: 12px;
    transition: 0.3s all;
    align-items: center;
    justify-content: space-between;
}

.SectionComponents.menu .SectionComponents_name_active {
    background: #E8F2F1;
    border-radius: 8px;
}

.SectionComponents.menu .SectionComponents_name_icon {
    transition: 0.3s all;
}


.item {
    background-color: inherit !important;
}

.SubSectionComponent {
    background-color: #FFF !important;
}

.item-flex {
    display: flex !important;
    flex-direction: column;
    gap: 20px;
}