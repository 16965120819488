.ImageEditable {
    margin-top: 20px;
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
}

.ImageEditable > p {
    font-size: 14px;
    font-weight: bold;
}

.ImageEditable > img {
    width: 93%; 
}

.sidebar_img {
    border: 1px solid rgba(0,0,0,.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
    margin-bottom: 4px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
    background-color: #f0f6ff;
    padding: 15px;
}

.default_img img {
    -webkit-filter:brightness(0) invert(1);
            filter:brightness(0) invert(1);
    padding: 15px 0!important;
}
.ImageEditable > input {
    display: none;
}

.ImageEditable > .button {
    width: 93%;
}

.sizing {
    width: 93%;
    margin:5px 0;
}