.checkbox-toggle-content {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.checkbox-toggle-content .checkbox-toggle-label {
    color: #3f4f65;
    font-family: 'AvenirNext-DemiBold';
}

.checkbox-toggle .checkbox,
.dn,
.up {
    display: inline-block !important;
    vertical-align: middle;
}

.checkbox-toggle .ui.checkbox input,
.checkbox-toggle .ui.toggle.checkbox label:before {
    padding-top: 2px;
    font-size: xx-small;
    content: attr(data-content)
}

.checkbox-toggle .ui.checkbox input,
.checkbox-toggle .ui.toggle.checkbox label.up:before {
    padding-left: 8px;
    padding-top: 2px;
    color: white;
    display: flex;
    align-items: center;
}

.checkbox-toggle .ui.checkbox input,
.checkbox-toggle .ui.toggle.checkbox label.dn:before {
    padding-left: 24px;
    padding-top: 2px;
    color: black;
    display: flex;
    align-items: center;
}

.checkbox-toggle .ui.toggle.checkbox label:after {
    transition: background 0.3s ease 0s, right 0.3s ease 0s
}

.checkbox-toggle .ui.toggle.checkbox input:checked~.box:after,
.checkbox-toggle .ui.toggle.checkbox input:checked~label:after {
    left: 2.0rem;
}

.checkbox-toggle .ui.toggle.checkbox input:checked+label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:checked~.box:before,
.checkbox-toggle .ui.toggle.checkbox input:checked~label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:checked~.box:before,
.checkbox-toggle .ui.toggle.checkbox input:checked~label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:focus:checked~.box:before,
.checkbox-toggle .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #24554e !important;
}

.checkbox-toggle .ui.toggle.checkbox input:checked+label:after {
    transition: background 0.3s ease 0s, right 0.3s ease 0s
}

.checkbox-toggle .ui.checkbox input,
.ui.toggle.checkbox label:before {
    padding-top: 2px;
    font-size: xx-small;
    content: attr(data-content);
    width: 3.5rem;
}
