.fontSelector {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.fontSelector__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 8px 8px 12px;
    background-color: #ffffff;
    border: 1px solid #e7e9e9;
    border-radius: 12px;
    cursor: pointer;
}

.fontSelector__button.active .fontSelector__button__icon {
    transform: rotate(180deg);
}

.fontOptions {
    overflow-y: auto;
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 9999;
    top: 100%;
    border-radius: 12px;
    z-index: 1000;
    border: 1px solid #e7e9e9;
    max-height: 300px;
    scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
    scrollbar-width: thin;
    box-sizing: border-box;
    text-align: center;
}

.fontOptions::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 5px 0px;
    background-color: transparent;
}

.fontOptions::-webkit-scrollbar {
    width: 3px;
    height: 2px;
    background-color: transparent;
}

.fontOptions::-webkit-scrollbar-thumb {
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
    background-color: #e2e2e2;
}

.fontOptions__item {
   padding: 10px;
   cursor: pointer;
   max-width: 100%;
}