.delete__modal {
    z-index: 99999999;
   position: absolute;
}
.delete__modal__background {
   background: rgba(19, 31, 30, 0.4);
   width: 100%;
   height: 100vh;
   position: fixed;
   top: 0;
   z-index: 99999999;
   left: 0;
}
.delete__modal__content {
   background: #fff;
   border-radius: 12px;
   padding: 40px;
   z-index: 99999999;
   display: flex;
   flex-direction: column;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   gap: 64px;
   align-items: flex-end;
}
.delete__modal__content__span {
   max-height: 300px;
   overflow-y: auto;
   scrollbar-color: rgba(119, 119, 119, 0.3) #e2e2e2;
   scrollbar-width: thin;
   box-sizing: border-box;
}
.delete__modal__content__span::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}
.delete__modal__content__span::-webkit-scrollbar {
   width: 5px;
   height: 5px;
   background-color: transparent;
}
.delete__modal__content__span::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.3);
   background-color: #e2e2e2;
}
.delete__modal__content__footer {
   display: flex;
   justify-content: flex-end;
   gap: 16px;
}
