.editor__top{
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    border: 1px solid #E7E9E9;
}


.editor__top__wrapper{
    display: flex;
    align-items: center;
    padding: 18px 24px;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 1024px) {
    .editor__top__wrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
}

.editor__top__wrapper__sizes{
    display: flex;
    gap: 8px;
    align-items: center;
}

.editor__top__wrapper__sizes__button{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.editor__top__wrapper__sizes__active{
    border: 1px solid #24554E;
    border-radius: 8px;
   
}

.editor__top__wrapper__sizes__active path{
    fill: #24554E;
}

.editor__top__wrapper__actions{
    display: flex;
    gap: 12px;
    align-items: center;
}

.editor__top__wrapper__actions__undo{
    display: flex;
    gap: 8px;
    align-items: center;
}

.editor__top__wrapper__actions__button{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
}

.editor__top__wrapper__actions__line{
    height: 24px;
    width: 1px;
    margin-right: 8px;
    background-color: #D9D9D9;
}

.editor__top__wrapper__left{
    margin-left: 32px;
}


.mobile__buttons {
    display: none;
}

@media (max-width: 1024px) {
    .mobile__buttons {
        display: flex;
    }
}

.desktop__buttons {
    display: flex;
}

@media (max-width: 1024px) {
    .desktop__buttons {
        display: none;
    }

    .mobile__buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .mobile__buttons__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .editor__top__wrapper__actions__line {
        display: none;
    }
}