.textBasic {
   font-style: normal;
   font-stretch: normal;
   position: relative;
   letter-spacing: normal;
   -webkit-touch-callout: text;
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -ms-user-select: text;
   user-select: text;
}
.textBasic div{
   padding: 5px;
}
.mini-text{
   font-weight: 500;
   font-size: 12px;
   line-height: 150%;
   font-feature-settings: 'tnum' on, 'lnum' on;
   color: #727978;
   position: absolute;
   margin-left: 4px;
   top: 0%;
   white-space: nowrap;
}

.textBasic_type_regular {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 168%;
   color: var(--greyscale_60);
}

.textBasic_type_select {
   font-weight: 400;
   line-height: 132%;
   color: var(--greyscale_90);
}
.textBasic_type_select_large {
   font-weight: 400;
   line-height: 168%;
   color: var(--greyscale_90);
}
.textBasic_type_regularDefault {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 168%;
   color: var(--greyscale_90);
}

.textBasic_type_regularDefaultGrey {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 168%;
   color: #727978;
}

.textBasic_type_regularDefaultGrey145 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 145%;
   color: #727978;
}

.textBasic_type_regularDefault145 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 145%;
   color: var(--greyscale_90);
}


.textBasic_type_regularDefaultSmallX {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 132%;
   color: var(--greyscale_90);
}
.textBasic_type_regularDefaultSmall {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 132%;
   color: var(--greyscale_90);
}
.textBasic_type_regularLarge {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 150%;
   color: var(--greyscale_90);
}

.textBasic_type_medium {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 133%;
   color: #131f1e;
}
.textBasic_type_regular148 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 148%;
   color: var(--greyscale_90);
}
.textBasic_type_regular160 {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 160%;
   color: var(--greyscale_90);
}
.textBasic_type_mediumLarge {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 168%;
   color: var(--greyscale_90);
}

.textBasic_type_mediumLargeGrey {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 168%;
   color: #727978;
}

.textBasic_type_mediumSmall {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 148%;
   color: var(--greyscale_90);
}

.textBasic_type_mediumXSmall {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 140%;
   color: var(--greyscale_90);
}


.textBasic_type_medium160 {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 160%;
   color: var(--greyscale_90);
}

.textBasic_type_medium150{
   font-family: "Roboto";
   font-weight: 500;
   line-height: 150%;
   color: var(--greyscale_90);
}

.textBasic_type_medium153 {
   font-family: "Roboto";
   font-weight: 500;
   line-height: 153%;
   color: var(--greyscale_90);
}
.textBasic_type_bold {
   font-family: "Roboto";
   font-weight: 700;
   line-height: 153%;
   color: var(--greyscale_90);
}
.textBasic_type_bold100 {
   font-family: "Roboto";
   font-weight: 700;
   line-height: 100%;
   color: var(--greyscale_90);
}


.textBasic_type_regularMin {
   font-family: "Roboto";
   font-weight: 400;
   line-height: 130%;
   color: #131f1e;
}

.textBasic_size_xlarge {
   font-size: 20px;
}

.textBasic_size_large {
   font-size: 18px;
}

.textBasic_size_medium {
   font-size: 16px;
}

.textBasic_size_small {
   font-size: 14px;
}

.textBasic_size_xsmall {
   font-size: 12px;
}

.textBasic_size_xx_small {
   font-size: 10px;
}

.textBasic_size_xxlarge {
   font-size: 24px;
}

.textBasic_size_size_28 {
   font-size: 28px;
}

.textBasic_size_size_40 {
   font-size: 40px;
}

.text-with-icon {
   display: flex;
   gap: 11px;
   align-items: center;
}
