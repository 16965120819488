.iconButton {
   background-color: inherit;
   border: none;
   padding: 4px;
   cursor: pointer;
   box-sizing: border-box;
   border-radius: 8px;
   transition: 0.3s ease-in-out;
   display: flex;
   align-items: center;
}
.iconButton__light:hover {
   background-color: #e8f2f1;
}
.iconButton__light:active {
   background: #a6c9c5;
}
.iconButton__light path {
   fill: #24554e;
}
.iconButton__primary {
   border: 1px solid #24554e;
}
.iconButton__delete:hover {
   background: #fff1f1;
}
.iconButton__delete:active {
   background: #f8c5c5;
}
.iconButton__delete path {
   fill: #d12d36;
}
.iconButton__delete__border {
   border: 1px solid #d12d36;
}
.iconButton__light__border {
   border: 1px solid #24554e;
}
.iconButton__disabled {
   background: rgba(19, 31, 30, 0.1);
   border: 1px solid rgba(19, 31, 30, 0.4);
   box-sizing: border-box;
}
.iconButton__disabled path {
   fill: rgba(19, 31, 30, 0.4);
}
