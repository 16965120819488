.input_new {
   font-family: "Roboto" !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 168% !important;
   font-feature-settings: "tnum" on, "lnum" on !important;
   color: var(--greyscale_90) !important;
   outline: none !important;
   border: none !important;
   background-color: inherit !important;
   width: 100% !important;
   padding: 0px !important;
}

.input_new_date {
   cursor: text;
}

.inputNew {
   min-height: 44px;
}

.inputNew_disabled {
   outline: none;
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
   padding: 12px 16px;
   display: flex;
   align-items: center;
}

.input_header {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.inputSearch .inputNew {
   padding: 6px 16px;
   min-height: 44px;
}

.inputSearch input::placeholder {
   color: #727978;
}

.inputNew__search {
   display: flex;
   width: 100%;
   align-items: center;
}

.inputNew {
   position: relative;
   max-width: 100%;
   background: #fff;
   outline: none;
   border: 1px solid var(--greyscale_10);
   box-sizing: border-box;
   border-radius: 12px;
   padding: 8px 16px;
   display: flex;
   align-items: center;
   height: 100%;
   justify-content: space-between;
}

.inputNew__timepicker {
   border: none !important;
   outline: none !important;
   width: 100%;
   padding: 0px !important;
   box-shadow: none !important;
}

.inputNew__timepicker .ant-picker-input {
   display: flex !important;
}

.inputNew__timepicker .ant-picker-input input {
   width: 100%;
}

.inputNew__timepicker .ant-picker-suffix {
   display: block;
}

.inputNew input {
   background-color: inherit !important;
}

.inputNew .inputNew_icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: auto;
}

.inputNew .inputNew_icon_inactive path {
   fill: #727978;
}

.inputNew svg {
   cursor: pointer;
   margin-right: 8px;
}

.inputNew_search svg {
   margin-left: 8px;
   margin-right: 0px;
}

.inputNew .show-hide {
   cursor: pointer;
   display: flex;
   align-items: flex-end;
}

.inputNew_helper {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 150%;
   color: var(--greyscale_40);
   text-align: right;
   font-feature-settings: "tnum" on, "lnum" on;
}

.inputNew_active {
   background: #fff;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   box-shadow: 0px 0px 4px #54938b;
   border-radius: 12px;
}

.inputNew_label {
   display: inline-block;
   font-family: "Roboto";
   font-style: normal;
   top: -58%;
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   transition: 0.2s ease-in-out;
   font-feature-settings: "tnum" on, "lnum" on;
   color: var(--greyscale_90);
}

.inputNew_label_error {
   color: var(--additional_error);
}

.inputNew_label_disabled {
   color: var(--greyscale_30);
}

.inputNew_label_animation {
   position: absolute;
   color: var(--greyscale_40) !important;
   top: 50%;
   transform: translateY(-50%);
}

.inputNew__calendar {
   position: relative;
}

.inputNew_focused .inputNew_label_animation {
   color: var(--greyscale_90) !important;
   top: -58%;
   transform: translateY(0%);
}

.input_date {
   display: flex;
   flex-direction: column;
   gap: 8px;
   width: 100%;
}

.input {
   width: 100%;
   display: flex;
   gap: 8px;
   flex-direction: column;
}

.input * {
   width: 100%;
}

.input_footer_message {
   margin-bottom: 2px;
   display: flex;
   align-items: center;
}

.input_footer_text {
   margin-left: 6px;
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 150%;
   font-feature-settings: "tnum" on, "lnum" on;
}

.input_footer_text_error {
   color: var(--additional_error);
}

.input_footer_text_success {
   color: var(--additional_success);
}

.input_footer_text_danger {
   color: var(--additional_danger);
}

.focused {
   box-shadow: 0px 0px 4px #54938b;
}

.input_new_disabled {
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
}

.input_buttons {
   text-align: end;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: space-between;
   gap: 2px;
}

.input_number {
   display: flex;
   flex-direction: column;
   gap: 8px;
   width: 100%;
}

.input_number .inputNew {
   padding: 4px 6px 4px 12px;
}

.input_number::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

.text_area {
   width: 100%;
}

.text_area textarea {
   scrollbar-color: var(--button-bg-color) #e2e2e2;
   scrollbar-width: 1px;
}

.text_area textarea::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}

.text_area textarea::placeholder {
   color: #727978;
}

.text_area textarea::-webkit-scrollbar {
   width: 5px;
   background-color: transparent;
}

.text_area textarea::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px var(--button-bg-color);
   -webkit-box-shadow: inset 0 0 6px var(--button-bg-color);
   background-color: #e2e2e2;
}

.text_area_new {
   width: 100%;
   background: #fff;
   padding: 12px 16px;
   outline: none;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
   resize: vertical;
}

.text_area_new:focus {
   box-shadow: 0px 0px 4px #54938b;
}

.text_area_new_disabled {
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   box-sizing: border-box;
   border-radius: 12px;
}

.input_number_button {
   cursor: pointer;
   background: #f0f2f2;
   border-radius: 4px;
   border: none;
   display: flex;
   align-items: center;
   padding: 5px 3px;
   justify-content: center;
   transition: 0.1s ease;
}

.input_number_button:active {
   background: #24554e;
}

.input_number_button:active svg path {
   fill: #fff;
}

.input_number_button_disabled {
   border-radius: 4px;
   border: none;
   display: flex;
   align-items: center;
   padding: 6px 4px;
   justify-content: center;
   transition: 0.1s ease;
   cursor: auto;
   background: inherit;
}

.input_number_button_disabled svg {
   margin: auto auto;
}

.input_number_button svg {
   margin: auto auto;
}

.inputNew_disabled {
   background: #f8fafa;
}

.calendar__wrapper {
   top: 100%;
   right: 0;
   position: absolute;
   z-index: 9999;
}

.inputNew__calendar__icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 32px;
   height: 32px;
}

.inputNew__calendar__icon svg {
   margin: 0px;
}

.inputNew__calendar__icon path {
   fill: var(--greyscale_40);
}

.inputNew__calendar__icon__selected path {
   fill: var(--greyscale_90);
}

.inputNew__calendar__icon__active {
   background-color: var(--primary_80);
   border-radius: 8px;
   width: 32px;
   height: 32px;
}

.inputNew__calendar__icon__active path {
   fill: var(--greyscale_0);
}

.input__default__label {
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   font-feature-settings: "tnum" on, "lnum" on;
   color: #131f1e;
   text-align: start;
}

.input_new_date {
   font-size: 12px;
}

.ant-picker-now a {
   font-weight: 400;
   font-size: 14px;
   line-height: 168%;
   font-feature-settings: "tnum" on, "lnum" on;
   color: #131f1e;
   text-align: start;
}

.ant-picker-now a:hover {
   color: #131f1e !important;
}

.ant-picker-ok {
   height: 100%;
   display: flex !important;
   align-items: center;
}

.ant-picker-ok button {
   font-family: "Roboto";
   font-weight: 400;
   font-size: 14px;
   border-radius: 12px;
   border: none;
   letter-spacing: 1%;
   text-align: center;
   width: auto;
   cursor: pointer;
   user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   background-color: var(--primary_80);
   border: 1px solid;
   transition: 0.3s ease;
   border: none !important;
   color: var(--greyscale_0);
}

.ant-picker-ok button:hover {
   background-color: var(--blackscale_70);
}

.ant-picker-ok button:active {
   background-color: var(--blackscale_80);
}

.ant-picker-ok button_disabled {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}

.ant-picker-ok button_disabled:hover {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}

.ant-picker-ok button_disabled:active {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}

.ant-picker-ok button_disabled svg path {
   fill: var(--blackscale_40);
}

.ant-picker-ranges {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
   background: #e8f2f1 !important;
}

.ant-picker-time-panel-column:hover {
   scrollbar-color: var(--button-bg-color) #e2e2e2;
   scrollbar-width: 1px;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: transparent;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar {
   width: 5px;
   background-color: transparent;
}

.ant-picker-time-panel-column:hover::-webkit-scrollbar-thumb {
   border-radius: 3px;
   box-shadow: inset 0 0 6px var(--button-bg-color);
   -webkit-box-shadow: inset 0 0 6px var(--button-bg-color);
   background-color: #e2e2e2;
}

.inputNew__copy__icon {
   display: flex;
   align-items: center;
   max-width: max-content;
}

.inputNew__copy__icon path {
   fill: #727978;
}

.inputNew__icon {
   display: flex;
   align-items: center;
   max-width: max-content;
}

.inputNew__icon svg {
   margin-right: -1px;
}


.inputLeftText {
   display: flex;
   gap: 1px;
   align-items: center;
}

.inputLeftText__content {
   width: auto;
   font-size: 12px;
}