.SectionComponents {
    width: 100% !important;
}

.SectionComponents.menu {
    padding-bottom: 16px;
}

.SectionComponents.menu .item {
    padding: 4px 16px;
}

.SectionComponents.menu .item:before {
    display: none;
}

.SectionComponents.menu .SectionComponents_name {
    padding: 10px 12px;
    border: 1px solid #dfe5eb;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Avenir Next";

}

.SectionComponents.menu .SectionComponents_name .component_icon_up {
    display: none;
}

.SectionComponents.menu .SectionComponents_name .component_name {
    color: #3f4f65;
}

.SectionComponents.menu .item img {
    width: 16px !important;
}

.SectionComponents.menu .item .header {
    padding: 10px !important;
}

.SectionComponent img {
    border: none !important;
}

.SectionComponents .SectionComponent {
    /* background-color:#f7f7f7; */
    padding: 16px;
}

.SectionComponent {
    -webkit-transition: 1s all;
    -o-transition: 1s all;
    transition: 1s all;
}

#section_remove_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.link_delete {
    box-sizing: border-box;
    width: 90%;
    box-sizing: border-box;
    background: #24554e !important;
    color: #fff !important;
    margin: 12px 16px !important;
}

/* .sidebarContainer .mce-notification-inner {
    display:none!important;
}

body .mce-widget.mce-notification.mce-notification-warning.mce-has-close.mce-in {
    display: none!important;
} */
