.btnBasic {
   font-family: "Roboto";
   font-weight: 400;
   font-size: 14px;
   border-radius: 12px;
   border: none;
   letter-spacing: 1%;
   text-align: center;
   width: auto;
   cursor: pointer;
   user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   color: var(--greyscale_0);
   padding: 13px 16px;
   gap: 8px;
   white-space: nowrap;
}
.btnBasic div {
   display: flex;
   align-items: center;
   justify-content: center;
}
.btnBasic svg path {
   fill: var(--greyscale_0);
}
.btnBasic_theme_change {
   background: rgba(19, 31, 30, 0.4);
   border: 1px solid #fff;
}
.btnBasic_theme_canva {
   background: #20c4cb;
   width: 100%;
   transition: 0.3s ease;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 7px 16px 9px;
   gap: 4px;
   font-weight: 400;
   letter-spacing: 0.01em;
   font-size: 14px;
   line-height: 132%;
}
.btnBasic_theme_primary {
   background-color: var(--primary_80);
   border: 1px solid;
   transition: 0.3s ease;
}
.btnBasic_theme_primary:hover {
   background-color: var(--blackscale_70);
}
.btnBasic_theme_primary:active {
   background-color: var(--blackscale_80);
}
.btnBasic_theme_primary_disabled {
   opacity: 0.4;
}
.btnBasic_theme_primary_disabled:hover {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}
.btnBasic_theme_primary_disabled:active {
   background-color: var(--blackscale_20);
   color: var(--blackscale_40);
}
.btnBasic_theme_primary_disabled svg path {
   fill: var(--blackscale_40);
}
.btnBasic_theme_more {
   border: 1px solid #24554e;
   border-radius: 12px;
   background-color: inherit;
   color: #24554e;
   width: 69px;
   height: 32px;
   font-size: 12px;
   padding: 0 !important;
   box-sizing: border-box;
   min-width: 69px;
}
.btnBasic_theme_error {
   border: 1px solid #d12d36;
   border-radius: 12px;
   background-color: inherit;
   color: #d12d36;
   height: 32px;
   font-size: 12px;
   box-sizing: border-box;
   min-width: 136px;
   width: 136px;
}
.btnBasic_theme_filter_true {
   background: #e7e9e9;
}
.btnBasic_theme_secondary {
   color: var(--primary_80);
   border: 1px solid var(--primary_80);
   background-color: inherit;
   transition: 0.3s ease;
}
.btnBasic_theme_secondary svg path {
   fill: var(--primary_80);
}
.btnBasic_theme_secondary:hover {
   background-color: var(--blackscale_10);
}
.btnBasic_theme_secondary:active {
   background-color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled {
   background-color: var(--blackscale_5);
   border-color: var(--blackscale_20);
   color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled:hover {
   background-color: var(--blackscale_5);
   border-color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled:active {
   background-color: var(--blackscale_5);
   border-color: var(--blackscale_20);
}
.btnBasic_theme_secondary_disabled svg path {
   fill: var(--blackscale_20);
}
.btnBasic_theme_red {
   background: #d12d36;
   border-radius: 12px;
   transition: 0.3s ease;
   color: #fff;
}
.btnBasic_theme_red svg path {
   fill: #fff;
}
.btnBasic_theme_tertiary {
   background-color: inherit;
   border: none;
   color: var(--greyscale_90);
}
.btnBasic_theme_tertiary svg path {
   fill: var(--greyscale_90);
}
.btnBasic_theme_tertiary:hover {
   color: var(--greyscale_40);
}
.btnBasic_theme_tertiary:hover svg path {
   fill: var(--greyscale_40);
}
.btnBasic_theme_tertiary:active {
   color: var(--greyscale_60);
}
.btnBasic_theme_tertiaryGreen {
   background-color: inherit;
   border: none;
   color: var(--primary_80);
}
.btnBasic_theme_tertiaryGreen svg path {
   fill: var(--primary_80);
}
.btnBasic_theme_tertiaryGreen:hover {
   color: var(--greyscale_40);
}
.btnBasic_theme_tertiaryGreen:hover svg path {
   fill: var(--greyscale_40);
}
.btnBasic_theme_tertiaryGreen:active {
   color: var(--greyscale_60);
}
.btnBasic_size_small {
   padding: 8px 12px;
}
.btnBasic_size_medium {
   padding: 9px 16px;
}
.btnBasic_size_large {
   min-height: 44px;
   padding: 12px 16px;
   max-height: 44px;
}
.btnBasic_size_xsmall {
   padding: 10px 12px;
   font-size: 12px;
   line-height: 132%;
   max-height: 36px;
}
.btnBasic_size_large120 {
   min-height: 44px;
   padding: 12px 16px;
   max-height: 44px;
   min-width: 120px;
}
.btnBasic_size_small63 {
   padding: 13px 16px;
   width: fit-content;
   max-height: 44px;
}
.btnBasic_size_large50 {
   padding: 13px 16px;
}
.btnBasic_theme_filter {
   background: #fff;
   display: flex;
   align-items: center;
   padding: 8px 13px;
   font-weight: 400;
   font-size: 12px;
   line-height: 132%;
   gap: 8px;
   min-height: 36px !important;
   color: #131f1e;
   min-width: min-content;
   border: 1px solid #e7e9e9;
   border-radius: 12px;
}
.btnBasic_theme_filter svg path {
   fill: #131f1e;
}
