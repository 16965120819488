/* .menu-item:hover{
    background-color: rgba(124, 183, 64, 0.1);
    border: 1px solid #7cb740;
    cursor: pointer;
} */

.all-menus {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.draggable-menus {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #3f4f65;
}

.menu-item-settings:hover {
    background-color: #7cb7401a;
    border: 1px solid #24554e;
    cursor: pointer;
}

.menu-item:hover .menu-item-left-drag {
    display: flex;
    align-items: center;
}

.menu-item-right i:hover {
    cursor: pointer;
}

.menu-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
}

.menu-item-left {
    display: flex;
    align-items: center;
}

.menu-item-left input {
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    line-height: 148%;
    border: none;
    outline: none;
}

.menu-item-left-drag {
    width: 16px !important;
    height: 12px !important;
    margin-right: 10px;
    display: none;
}

.menu-item-left img {
    width: 16px !important;
    height: 16px;
}

.menu-item-right i {
    padding-top: 5px;
    height: 20px;
}

.menu-item-right i:last-child {
    margin-right: 0;
}

.menu-item-right-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.menu-item {
    /* width: 100%!important;
    border: 1px solid #c2cedb;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 49px;*/
}

.menu-item-settings {
    width: 100% !important;
    border: 1px solid #c2cedb;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 49px;
}

.menu-item-draggable-disabled {
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 8px;
    height: 49px;
}

.menu-item-draggable {
    background-color: #fff;
    border-radius: 4px;
    height: 49px;
}

.menu-item-draggable-disabled:hover {
    background-color: rgba(124, 183, 64, 0.1);
    border: 1px solid #24554e;
    cursor: pointer;
}

.menu-item-draggable:hover {
    cursor: pointer;
}

.menu-footer-item {
    width: 100%;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    user-select: none;
}

.menu-footer-item .menu-item-left {
    display: flex;
    align-items: center;
}

.menu-item-right-actions {
    display: flex;
    align-items: center;
    gap: 12px;
}
