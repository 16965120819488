.info {
   background: #f8fafa;
   border: 1px solid #e7e9e9;
   padding: 12px;
   display: flex;
   border-radius: 12px;
   justify-content: space-between;
}
.info .info-left {
   display: flex;
   align-items: flex-start;
   gap: 12px;
}
.info .info-left div {
   display: flex;
}
.info div:nth-child(2) svg {
   cursor: pointer;
}
