.view_icons{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}
.desktop{
    display: flex;
    align-items: flex-end;
}
.tablet, .phone {
    display: flex;
}
.view_icons img {
    height: 16px;
}
